import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-custom-option-select',
    templateUrl: './app-custom-option-select.component.html',
    styleUrls: ['./app-custom-option-select.component.scss']
})
export class AppCustomOptionSelectComponent implements OnInit {
    @Input() controlName: string;
    @Input() form: FormGroup;
    @Input() options: Array<any> = [
        {label: 'SI', value: true},
        {label: 'NO', value: false}
    ];
    @Input() label:string = "Valor de la opción";

    constructor() {
    }

    valueChange(ev) {
        this.form.get(this.controlName).setValue(ev.value);
    }

    ngOnInit() {
    }

}
