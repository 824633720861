import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {VehicleClass} from '../../interfaces/vehicle-class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VehicleService} from '../../services/vehicle.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import * as moment from 'moment';
import {PartClass} from '../../interfaces/part-class';
import {PartService} from '../../services/part.service';
import {EstanteriaClass} from '../../interfaces/estanteria-class';
import {EstanteriaService} from '../../services/estanteria.service';
import {isNullOrUndefined} from 'util';
import {ConfigService} from '../../services/config.services';
import {Filter} from '../../interfaces/filter-class';
import {Observable, timer} from 'rxjs';
import {NavigationService} from '../../services/navigation.service';
import {PartsTypeClass} from '../../interfaces/parts-type-class';
import {PartTypeService} from '../../services/part-type.service';

declare let $: any, jQuery: any;

@Component({
  selector: 'app-app-dialog-add',
  templateUrl: './app-dialog-add.component.html',
  styleUrls: ['./app-dialog-add.component.scss']
})
export class AppDialogAddComponent implements OnInit {
  @HostBinding('class.mobile') mobile;
  @ViewChild('focusInput', {static: false}) focusInput: ElementRef;
  public autoselect: boolean = false;
  public entity: any;
  public form: FormGroup;
  public loading = false;
  public formDisable = false;
  public maxDate = moment().toDate();
  public moreData = false;
  public filterInModel: Filter = null;
  public lastIdGenerated: number = null;
  public loadingIdGenerated: boolean = false;

  get isMobile(): Observable<boolean> {
    return this.navigation.obsIsMobile;
  }

  constructor(public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private vehicleService: VehicleService,
              private partService: PartService,
              public configService: ConfigService,
              public navigation: NavigationService,
              private estanteriaService: EstanteriaService,
              private partTypeService: PartTypeService
  ) {
    $ = $ || jQuery;
    this.isMobile.subscribe(isMobile => {
      this.mobile = isMobile;
    });

  }

  changeAutoComplete(key, ev) {
    switch (key) {
      case 'marca':
        this.filterInModel = new Filter('idMarca', '=', ev.id);
    }
  }

  async getNextId() {
    //TODO ESTO SOLO SE EJECUTA SI SE ADICIONA DESDE LA VISTA PRINCIPAL Y NO DENTRO DE UN ELEMTO (Vehiculo o pieza)
    if (isNullOrUndefined(this.entity.form.get('id').value) || this.entity.form.get('id').value === '') {
      if (isNullOrUndefined(this.lastIdGenerated) && !this.loadingIdGenerated) {
        //ESTO ES PARA QUE NO HAGAEL REQUEST MAS DE UNA VEZ
        this.loadingIdGenerated = true;
        if (this.isInstanceVehicle())
          this.lastIdGenerated = await this.vehicleService.lastIdGenerated();
        if (this.isInstancePart())
          this.lastIdGenerated = await this.partService.lastIdGenerated();

      }
      this.loadingIdGenerated = false;

      // if(this.isInstancePart())
      //     id = await this.partService.lastIdGenerated();
      if (!isNullOrUndefined(this.lastIdGenerated))
        this.entity.form.get('id').setValue(this.lastIdGenerated);
    }
  }

  ngOnInit() {
    // console.log('ENTITY IN DIALOG ', this.entity);
    // if (this.formDisable)
    //     this.entity.form.disable();
    // else
    //     this.entity.form.enable();
    this.loadingIdGenerated = false;


    this.vehicleService.lastIndex.subscribe((id: number) => {
      console.log('NEXT GENERATE ID', id);
      console.log('NEXT VALUE ID', this.entity.form.get('id').value);
      this.lastIdGenerated = id;
      this.getNextId();
    });
    this.partService.lastIndex.subscribe((id: number) => {
      console.log('NEXT GENERATE ID', id);
      this.lastIdGenerated = id;
      this.getNextId();
    });


    this.form = new FormGroup({
      // id: new FormControl(null),
      // fichaMatricula: new FormControl({value: '', disabled: false}, Validators.compose([
      //     Validators.required,
      //     Validators.minLength(20)
      // ]))
    });
    //TODO TO FIX AUTOCOMPLETE ERROR IN ANGULAR
    this.autoFocus();
  }

  autoFocus() {

    if (this.isInstanceVehicle() || this.isInstancePart()) {
      timer(1500).subscribe(val => $('div.cdk-overlay-pane > div.mat-autocomplete-panel').hide());
    }

  }

  public isInstanceVehicle() {
    return this.entity instanceof VehicleClass;
  }

  public isInstancePart() {
    return this.entity instanceof PartClass;
  }

  public isInstancePartTypes() {
    return this.entity instanceof PartsTypeClass;
  }

  public isInstanceEstanteria() {
    return this.entity instanceof EstanteriaClass;
  }

  uploadImage(ev, num: number = 1) {
    this.entity.form.get('imageFile' + num).setValue(ev);
    if (isNullOrUndefined(ev))
      this.entity.form.get('fichaImagen' + num + 'Url').setValue(ev);
  }

  validateDateEntity(date: any): any {
    if (date === 'Invalid date' || date === '' || isNullOrUndefined(date) || !moment(date, 'DD-MM-YYYY').isValid())
      return '';
    else
      return moment(date).format('DD-MM-YYYY');
  }

  saveDataEntity() {
    this.loading = true;
    if (this.entity.form.valid) {
      const values = this.entity.form.value;
      values.id = null;
      if (this.isInstanceVehicle()) {
        values.fechaSalida = this.validateDateEntity(values.fechaSalida);
        values.fechaEntrada = this.validateDateEntity(values.fechaEntrada);
        this.vehicleService.create(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Vehículo insertado satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 30000});
            this.dialogRef.close(true);
            // this.vehicleService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstancePart()) {
        values.fechaSalida = this.validateDateEntity(values.fechaSalida);
        values.fechaInventariado = this.validateDateEntity(values.fechaInventariado);
        this.partService.create(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Pieza insertada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 30000});
            this.dialogRef.close(true);
            // this.partService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstancePartTypes()) {
        this.partTypeService.create(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Tipo de pieza insertada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 30000});
            this.dialogRef.close(true);
            // this.partService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstanceEstanteria()) {
        this.estanteriaService.create(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Estanteria insertada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 30000});
            this.dialogRef.close(true);
            // this.partService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
    }
  }

  updateEntity() {
    this.loading = true;
    if (this.entity.form.valid) {
      const values = this.entity.form.value;
      values.id = this.entity.id;
      if (this.isInstanceVehicle()) {
        values.fechaSalida = this.validateDateEntity(values.fechaSalida);
        values.fechaEntrada = this.validateDateEntity(values.fechaEntrada);
        this.vehicleService.update(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Vehículo modificado satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
            this.dialogRef.close(true);
            // this.vehicleService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstancePart()) {
        values.fechaSalida = this.validateDateEntity(values.fechaSalida);
        values.fechaInventariado = this.validateDateEntity(values.fechaInventariado);
        this.partService.update(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Pieza modificada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
            this.dialogRef.close(true);
            // this.partService.index(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstancePartTypes()) {
        this.partTypeService.update(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Tipo de pieza modificada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
            this.dialogRef.close(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
      if (this.isInstanceEstanteria()) {
        this.estanteriaService.update(values).subscribe((response: any) => {
          this.loading = false;
          if (response.success === 200 || response.success === true) {
            this.snackBar.open('Estanteria modificada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
            this.dialogRef.close(true);
          } else {
            this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

          }
        }, (error: any) => {
          setTimeout(function () {
            this.loading = false;
          }.bind(this), 4000);
          // this.loading = false;
          this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

        });
      }
    }
  }

  deleteEntity() {
    this.loading = true;
    if (this.isInstanceVehicle()) {
      this.vehicleService.delete(this.entity.form.value.id).subscribe((response: any) => {
        this.loading = false;
        if (response.success === 200 || response.success === true) {
          this.snackBar.open('Vehículo eliminado satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
          this.dialogRef.close(true);
          // this.vehicleService.index(true);
        } else {
          this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

        }
      }, (error: any) => {
        setTimeout(function () {
          this.loading = false;
        }.bind(this), 4000);
        // this.loading = false;
        this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

      });
    }
    if (this.isInstancePart()) {
      this.partService.delete(this.entity.form.value.id).subscribe((response: any) => {
        this.loading = false;
        if (response.success === 200 || response.success === true) {
          this.snackBar.open('Pieza eliminada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
          this.dialogRef.close(true);
          // this.vehicleService.index(true);
        } else {
          this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

        }
      }, (error: any) => {
        setTimeout(function () {
          this.loading = false;
        }.bind(this), 4000);
        // this.loading = false;
        this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

      });
    }
    if (this.isInstancePartTypes()) {
      this.partTypeService.delete(this.entity.form.value.id).subscribe((response: any) => {
        this.loading = false;
        if (response.success === 200 || response.success === true) {
          this.snackBar.open('Tipo de pieza eliminada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
          this.dialogRef.close(true);
          // this.vehicleService.index(true);
        } else {
          this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

        }
      }, (error: any) => {
        setTimeout(function () {
          this.loading = false;
        }.bind(this), 4000);
        // this.loading = false;
        this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

      });
    }
    if (this.isInstanceEstanteria()) {
      this.estanteriaService.delete(this.entity.form.value.id).subscribe((response: any) => {
        this.loading = false;
        if (response.success === 200 || response.success === true) {
          this.snackBar.open('Estanteria eliminada satisfactoriamente', 'x', <MatSnackBarConfig>{duration: 3000});
          this.dialogRef.close(true);
          // this.vehicleService.index(true);
        } else {
          this.snackBar.open(response.msg || 'Datos Incorrectos por favor verifíquelos', 'x', <MatSnackBarConfig>{duration: 4000});

        }
      }, (error: any) => {
        setTimeout(function () {
          this.loading = false;
        }.bind(this), 4000);
        // this.loading = false;
        this.snackBar.open('Ha ocurrido un error en el servicio.', 'x', <MatSnackBarConfig>{duration: 5000});

      });
    }

  }


}
