import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PagesRoutingModule} from './pages-routing.module';
import {Error404PageComponent} from './error404-page/error404-page.component';
import {HomePageComponent} from './home-page/home-page.component';
import {PagesComponent} from './pages.component';
import {SharedModule} from '../shared/shared.module';
import { VehiclesComponent } from './vehicles/vehicles.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PartsComponent } from './parts/parts.component';
import { EstanteriasComponent } from './estanterias/estanterias.component';
import {PartsTypesComponent} from './parts-types/parts-types.component';


@NgModule({
    declarations: [
      PagesComponent,
      HomePageComponent,
      Error404PageComponent,
      VehiclesComponent,
      PartsComponent,
      EstanteriasComponent,
      PartsTypesComponent
    ],
    imports: [
        CommonModule,
        PagesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class PagesModule {
}
