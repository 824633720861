import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class SeguimientoClass {
    form: FormGroup;
    public id: number = null;
    public portes:boolean = null;
    public fichaGrua = null;
    public gruaProcedencia = null;
    public estadoBaja:string = '';
    public bajaHecha:boolean = null;
    public ubicacionTirado:boolean = null;
    public ubicacionObsv = '';
    public nombreVendedor = '';
    public dniVendedor = '';
    public domicilioVendedor = '';
    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            portes: new FormControl({value: this.portes, disabled: false}),
            fichaGrua: new FormControl({value: (!(isNullOrUndefined(this.fichaGrua)) ? this.fichaGrua.id : null), disabled: false}),
            gruaProcedencia: new FormControl({value: (!(isNullOrUndefined(this.gruaProcedencia)) ? this.gruaProcedencia.id : null) , disabled: false}),
            estadoBaja: new FormControl({value: this.estadoBaja, disabled: false}),
            bajaHecha: new FormControl({value: this.bajaHecha, disabled: false}),
            ubicacionTirado: new FormControl({value: this.ubicacionTirado, disabled: false}),
            ubicacionObsv: new FormControl({value: this.ubicacionObsv, disabled: false}),
            nombreVendedor: new FormControl({value: this.nombreVendedor, disabled: false}),
            dniVendedor: new FormControl({value: this.dniVendedor, disabled: false}),
            domicilioVendedor: new FormControl({value: this.domicilioVendedor, disabled: false})
        });
    }
}
