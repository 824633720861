import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {isEmpty} from 'rxjs/internal/operators';
import {isNullOrUndefined} from 'util';
import {CarroceriaClass} from './carroceria-class';
import {EscapeClass} from './escape-class';
import {LucesClass} from './luces-class';
import {LunasClass} from './lunas-class';
import {RetrovisoresClass} from './retrovisores-class';
import {CalefaccionClass} from './calefaccion-class';
import {InstPuertasClass} from './instPuertas-class';
import {SeguridadClass} from './seguridad-class';
import {TapiceriaClass} from './tapiceria-class';
import {RodajeClass} from './rodaje-class';
import {MotorClass} from './motor-class';
import {CambioClass} from './cambio-class';
import {SeguimientoClass} from './seguimiento-class';
import {EquipamientoClass} from './equipamiento-class';

export class VehicleClass {
    form: FormGroup;
    moreData = true;
    marcaDesc ='';
    modeloDesc='';

    constructor(public id: number = null,
                public fechaEntrada: string = null,
                public fechaSalida: string = null,
                public fichaMatricula: string = null,
                public fichaTipoMotor: string = null,
                public fichaDescripcion: string = null,
                public fichaAnyo: string = null,
                public fichaKilometros: string = null,
                public fichaColor: string = null,
                public fichaBastidor: string = null,
                public fichaNumeroPuertas: string = null,
                public fichaImagen1Url: string = null,
                public fichaImagen1SmallThumbnailUrl: string = null,
                public fichaImagen1AlbumId: string = null,
                public fichaImagen1Id: string = null,
                public fichaImagen2Url: string = null,
                public fichaImagen2SmallThumbnailUrl: string = null,
                public fichaImagen2AlbumId: string = null,
                public fichaImagen2Id: string = null,
                public ciPrecio: string = null,
                public observaciones: string = null,
                public bajaLogica: string = null,
                public fichaMarca: string = null,
                public fichaModelo: string = null,
                public fichaTipo: string = null,
                public fichaVariante: string = null,
                public idCarroceriaValores: CarroceriaClass = new CarroceriaClass(),
                public idLucesValores: LucesClass = new LucesClass(),
                public idLunasValores: LunasClass = new LunasClass(),
                public idRetrovisoresValores: RetrovisoresClass = new RetrovisoresClass(),
                public idCalefaccionValores: CalefaccionClass = new CalefaccionClass(),
                public idInstPuertasValores: InstPuertasClass = new InstPuertasClass(),
                public idSeguridadValores: SeguridadClass = new SeguridadClass(),
                public idTapiceriaValores: TapiceriaClass = new TapiceriaClass(),
                public idRodajeValores: RodajeClass = new RodajeClass(),
                public idMotorValores: MotorClass = new MotorClass(),
                public idCambioValores: CambioClass = new CambioClass(),
                public idEscapeValores: EscapeClass = new EscapeClass(),
                public fichaSeguimientoValores: SeguimientoClass = new SeguimientoClass(),
                public idEquipamentoValores: EquipamientoClass = new EquipamientoClass(),
                public tara: string = '',
                public culata: string = '',
                public parteBaja: string = '',
                public caja: string = '',
                public motor: string = ''
                ) {
        // FORMATING DATE
        const fechaS = (!moment(this.fechaSalida, 'DD-MM-YYYY').isValid()) ? null : moment(this.fechaSalida, 'DD-MM-YYYY').toDate();
        const fechaE = (!moment(this.fechaEntrada, 'DD-MM-YYYY').isValid()) ? moment().toDate() : moment(this.fechaEntrada, 'DD-MM-YYYY').toDate();

        this.form = new FormGroup({
            imageFile1: new FormControl(),
            imageFile2: new FormControl(),
            id: new FormControl(this.id),
            fichaVariante: new FormControl({value: this.fichaVariante, disabled: false}, Validators.compose([])),
            fichaTipo: new FormControl({value: this.fichaTipo, disabled: false}, Validators.compose([])),
            fichaMarca: new FormControl({value: this.fichaMarca, disabled: false}, Validators.compose([
                Validators.required
            ])),
            fichaModelo: new FormControl({value: this.fichaModelo, disabled: false}, Validators.compose([
                Validators.required
            ])),
            fichaMatricula: new FormControl({value: this.fichaMatricula, disabled: false}, Validators.compose([
                // Validators.required,
                Validators.maxLength(20)
            ])),
            fichaTipoMotor: new FormControl({value: this.fichaTipoMotor, disabled: false}),
            fechaSalida: new FormControl({value: fechaS, disabled: false}),
            fechaEntrada: new FormControl({value: fechaE, disabled: false}),
            fichaDescripcion: new FormControl({value: this.fichaDescripcion, disabled: false}),
            fichaAnyo: new FormControl({value: this.fichaAnyo, disabled: false}, Validators.compose([
                Validators.maxLength(4),
                Validators.minLength(4)
            ])),
            fichaKilometros: new FormControl({value: this.fichaKilometros, disabled: false}),
            fichaColor: new FormControl({value: this.fichaColor, disabled: false}),
            fichaBastidor: new FormControl({value: this.fichaBastidor, disabled: false}),
            fichaNumeroPuertas: new FormControl({value: this.fichaNumeroPuertas, disabled: false}),
            fichaImagen1Url: new FormControl({value: this.fichaImagen1Url, disabled: false}),
            fichaImagen1SmallThumbnailUrl: new FormControl({value: this.fichaImagen1SmallThumbnailUrl, disabled: false}),
            fichaImagen1AlbumId: new FormControl({value: this.fichaImagen1AlbumId, disabled: false}),
            fichaImagen1Id: new FormControl({value: this.fichaImagen1Id, disabled: false}),
            fichaImagen2Url: new FormControl({value: this.fichaImagen2Url, disabled: false}),
            fichaImagen2SmallThumbnailUrl: new FormControl({value: this.fichaImagen2SmallThumbnailUrl, disabled: false}),
            fichaImagen2AlbumId: new FormControl({value: this.fichaImagen2AlbumId, disabled: false}),
            fichaImagen2Id: new FormControl({value: this.fichaImagen2Id, disabled: false}),
            ciPrecio: new FormControl({value: this.ciPrecio, disabled: false}),
            observaciones: new FormControl({value: this.observaciones, disabled: false}),
            bajaLogica: new FormControl({value: this.bajaLogica, disabled: false}),
            tara: new FormControl({value: this.tara, disabled: false}),
            culata: new FormControl({value: this.culata, disabled: false}),
            parteBaja: new FormControl({value: this.parteBaja, disabled: false}),
            caja: new FormControl({value: this.caja, disabled: false}),
            motor: new FormControl({value: this.motor, disabled: false}),
            // CARROCERIA VALUES
            // idCarroceria: new FormControl({value: this.idCarroceria, disabled: false}),
            // puertaDI: new FormControl({value: true, disabled: false})

            idCarroceriaValores: (!isNullOrUndefined(this.idCarroceriaValores)) ? this.idCarroceriaValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idLucesValores: (!isNullOrUndefined(this.idLucesValores)) ? this.idLucesValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idLunasValores: (!isNullOrUndefined(this.idLunasValores)) ? this.idLunasValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idRetrovisoresValores: (!isNullOrUndefined(this.idRetrovisoresValores)) ? this.idRetrovisoresValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idCalefaccionValores: (!isNullOrUndefined(this.idCalefaccionValores)) ? this.idCalefaccionValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idInstPuertasValores: (!isNullOrUndefined(this.idInstPuertasValores)) ? this.idInstPuertasValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idSeguridadValores: (!isNullOrUndefined(this.idSeguridadValores)) ? this.idSeguridadValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idTapiceriaValores: (!isNullOrUndefined(this.idTapiceriaValores)) ? this.idTapiceriaValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idRodajeValores: (!isNullOrUndefined(this.idRodajeValores)) ? this.idRodajeValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idMotorValores: (!isNullOrUndefined(this.idMotorValores)) ? this.idMotorValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idCambioValores: (!isNullOrUndefined(this.idCambioValores)) ? this.idCambioValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idEscapeValores: (!isNullOrUndefined(this.idEscapeValores)) ? this.idEscapeValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            fichaSeguimientoValores: (!isNullOrUndefined(this.fichaSeguimientoValores)) ? this.fichaSeguimientoValores.form : new FormControl({
                value: null,
                disabled: false
            }),
            idEquipamentoValores: (!isNullOrUndefined(this.idEquipamentoValores)) ? this.idEquipamentoValores.form : new FormControl({
                value: null,
                disabled: false
            })
        });
        this.form.get('fichaMatricula').valueChanges.subscribe(value => {
            this.fichaMatricula = value;
        });
    }
}
