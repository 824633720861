import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/auth/authentication.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {Router} from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('ERROR', err);
      if (err.status === 401 || err.code === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        this.router.navigate(['auth/login']);
        // location.reload(true);
      }

      let error = err.error.message || err.statusText;
      if (error === 'Account is disabled.')
        error = 'La cuenta ha sido deshabilitada. Por favor contácte al administrador';
      if (error === 'Invalid credentials.')
        error = 'Credenciales inválidas. Por favor revise su usuario y contraseña';
      return throwError(error);
    }));
  }
}
