import { Component } from '@angular/core';
import * as moment from 'moment';
import {AppUtils} from './shared/helpers/app.utils';
import {User} from './shared/interfaces/user';
import {Router} from '@angular/router';
import {AuthenticationService} from './shared/services/auth/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ng-scrapping';
  currentUser: User ;
    constructor( private router: Router,
                 private authenticationService: AuthenticationService) {
        moment.updateLocale('es', null);
        // CLEANING FILTERS
        AppUtils.del('FILTER-VEHICLE');
        AppUtils.del('FILTER-PART');
        AppUtils.del('FILTER-ESTANTERIA');
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['auth/login']);
  }
}
