import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class RodajeClass {
    form: FormGroup;
    public id: number = null;
    public cremallera:boolean = null;
    public canna:boolean = null;
    public transmisionDI:boolean = null;
    public transmisionDD:boolean = null;
    public transmisionTD:boolean = null;
    public transmisionCentral:boolean = null;
    public amortDI:boolean = null;
    public amortDD:boolean = null;
    public amortTI:boolean = null;
    public amortTD:boolean = null;
    public manguetaDI:boolean = null;
    public manguetaDD:boolean = null;
    public manguetaTI:boolean = null;
    public manguetaTD:boolean = null;
    public puenteTras:boolean = null;
    public brazoSusIzdo:boolean = null;
    public brazoSusDcho:boolean = null;
    public pinzaFrenoDI:boolean = null;
    public pinzaFrenoDD:boolean = null;
    public pinzaFrenoTI:boolean = null;
    public pinzaFrenoTD:boolean = null;
    public tamborTI:boolean = null;
    public tamborTD:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            cremallera: new FormControl({value: this.cremallera, disabled: false}),
            canna: new FormControl({value: this.canna, disabled: false}),
            transmisionDI: new FormControl({value: this.transmisionDI, disabled: false}),
            transmisionDD: new FormControl({value: this.transmisionDD, disabled: false}),
            transmisionTD: new FormControl({value: this.transmisionTD, disabled: false}),
            transmisionCentral: new FormControl({value: this.transmisionCentral, disabled: false}),
            amortDI: new FormControl({value: this.amortDI, disabled: false}),
            amortDD: new FormControl({value: this.amortDD, disabled: false}),
            amortTI: new FormControl({value: this.amortTI, disabled: false}),
            amortTD: new FormControl({value: this.amortTD, disabled: false}),
            manguetaDI: new FormControl({value: this.manguetaDI, disabled: false}),
            manguetaDD: new FormControl({value: this.manguetaDD, disabled: false}),
            manguetaTI: new FormControl({value: this.manguetaTI, disabled: false}),
            manguetaTD: new FormControl({value: this.manguetaTD, disabled: false}),
            puenteTras: new FormControl({value: this.puenteTras, disabled: false}),
            brazoSusIzdo: new FormControl({value: this.brazoSusIzdo, disabled: false}),
            brazoSusDcho: new FormControl({value: this.brazoSusDcho, disabled: false}),
            pinzaFrenoDI: new FormControl({value: this.pinzaFrenoDI, disabled: false}),
            pinzaFrenoDD: new FormControl({value: this.pinzaFrenoDD, disabled: false}),
            pinzaFrenoTI: new FormControl({value: this.pinzaFrenoTI, disabled: false}),
            pinzaFrenoTD: new FormControl({value: this.pinzaFrenoTD, disabled: false}),
            tamborTI: new FormControl({value: this.tamborTI, disabled: false}),
            tamborTD: new FormControl({value: this.tamborTD, disabled: false})
        });
    }
}
