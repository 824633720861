import {InjectionToken} from '@angular/core';

export let ROUTES_CONFIG = new InjectionToken('routes.config');

const basePaths = {
  vehicles: 'vehicles'
};

const routesNames = {
  home: 'inicio',
  vehicles: 'vehicles',
  parts: 'parts',
  partstypes: 'partstypes',
  shelves: 'shelves',
  error404: '404',
  pages: {
    basePath: basePaths.vehicles
  }
};

export const RoutesConfig: any = {
  routesNames,
  routes: {
    home: `/${routesNames.home}`,
    vehicles: `/${routesNames.vehicles}`,
    parts: `/${routesNames.parts}`,
    partstypes: `/${routesNames.partstypes}`,
    shelves: `/${routesNames.shelves}`,
    error404: `/${routesNames.error404}`
  }
};


