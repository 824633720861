import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class CarroceriaClass {
    form: FormGroup;
    public id: number = null;
    public puertaDI: boolean = null;
    public puertaDD: boolean = null;
    public puertaTI: boolean = null;
    public puertaTD: boolean = null;
    public porton: boolean = null;
    public paragDel: boolean = null;
    public paragTras: boolean = null;
    public aletaIzda: boolean = null;
    public aletaDcha: boolean = null;
    public capot: boolean = null;
    public paseIzdo: boolean = null;
    public paseDcho: boolean = null;
    public manillaDI: boolean = null;
    public manillaDD: boolean = null;
    public bombinIzdo: boolean = null;
    public bombinDcho: boolean = null;
    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            puertaDI: new FormControl({value: this.puertaDI, disabled: false}),
            puertaDD: new FormControl({value: this.puertaDD, disabled: false}),
            puertaTI: new FormControl({value: this.puertaTI, disabled: false}),
            puertaTD: new FormControl({value: this.puertaTD, disabled: false}),
            porton: new FormControl({value: this.porton, disabled: false}),
            paragDel: new FormControl({value: this.paragDel, disabled: false}),
            paragTras: new FormControl({value: this.paragTras, disabled: false}),
            aletaIzda: new FormControl({value: this.aletaIzda, disabled: false}),
            aletaDcha: new FormControl({value: this.aletaDcha, disabled: false}),
            capot: new FormControl({value: this.capot, disabled: false}),
            paseIzdo: new FormControl({value: this.paseIzdo, disabled: false}),
            paseDcho: new FormControl({value: this.paseDcho, disabled: false}),
            manillaDI: new FormControl({value: this.manillaDI, disabled: false}),
            manillaDD: new FormControl({value: this.manillaDD, disabled: false}),
            bombinIzdo: new FormControl({value: this.bombinIzdo, disabled: false}),
            bombinDcho: new FormControl({value: this.bombinDcho, disabled: false})
        });
    }
}
