import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class EquipamientoClass {
    form: FormGroup;
    public id: number = null;
    public farosAntinieblas: boolean = null;
    public cierreCentralizado: boolean = null;
    public abs: boolean = null;
    public aireAcondicionado: boolean = null;
    public climatizador: boolean = null;
    public espejosElec: boolean = null;
    public elevalunasDelanteros: boolean = null;
    public elevalunasTraseros: boolean = null;
    public direccionAsist: boolean = null;
    public direccionElec: boolean = null;
    public retrovisorElec: boolean = null;
    public retrovisorManual: boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            farosAntinieblas: new FormControl({value: this.farosAntinieblas, disabled: false}),
            cierreCentralizado: new FormControl({value: this.cierreCentralizado, disabled: false}),
            abs: new FormControl({value: this.abs, disabled: false}),
            aireAcondicionado: new FormControl({value: this.aireAcondicionado, disabled: false}),
            climatizador: new FormControl({value: this.climatizador, disabled: false}),
            espejosElec: new FormControl({value: this.espejosElec, disabled: false}),
            elevalunasDelanteros: new FormControl({value: this.elevalunasDelanteros, disabled: false}),
            elevalunasTraseros: new FormControl({value: this.elevalunasTraseros, disabled: false}),
            direccionAsist: new FormControl({value: this.direccionAsist, disabled: false}),
            direccionElec: new FormControl({value: this.direccionElec, disabled: false}),
            retrovisorElec: new FormControl({value: this.retrovisorElec, disabled: false}),
            retrovisorManual: new FormControl({value: this.retrovisorManual, disabled: false})
        });
    }
}
