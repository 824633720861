import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {VehicleService} from '../../shared/services/vehicle.service';
import {Observable, Subscription, timer} from 'rxjs';
import {VehicleClass} from '../../shared/interfaces/vehicle-class';
import {isNullOrUndefined} from 'util';
import {MatDialog, MatDialogConfig, MatDialogRef, MatSort} from '@angular/material';
import {NavigationService} from '../../shared/services/navigation.service';
import {AppDialogAddComponent} from '../../shared/components/app-dialog-add/app-dialog-add.component';
import {EstanteriaClass} from '../../shared/interfaces/estanteria-class';
import {AppDialogSearchComponent} from '../../shared/components/app-dialog-search/app-dialog-search.component';
import {AppUtils} from '../../shared/helpers/app.utils';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit, AfterViewInit, OnDestroy {
  // MARCA, MODELO, TIPO MOTOR, CAJA
  displayedColumns: string[] = ['marcaDesc', 'modeloDesc', 'fichaTipoMotor', 'caja'];
  dataSource: MatTableDataSource<any>;
  subsVehicle: Subscription;
  paginatorService: Subscription;
  loading = true;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @HostBinding('class.mobile') mobile;
  data: any[] = [];
  _data: any[] = [];
  renderInit = false;
  public filter: Array<any> = [];
  public page = 1;
  public pageSize = 10;
  public pageIndex = 1;
  public pages = 1;
  public count = 0;
  public textSearch = '';

  public lastIndexToGenerated = null;

  get isMobile(): Observable<boolean> {
    return this.navigation.obsIsMobile;
  }

  dialogAdd: MatDialogRef<AppDialogAddComponent>;
  dialogSearch: MatDialogRef<AppDialogSearchComponent>;
  public currentUserData: any;
  public activeFilter: any;

  constructor(public dialog: MatDialog, private vehicleService: VehicleService, private navigation: NavigationService) {
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.currentUserData = AppUtils.load('currentUserData');
    this.isMobile.subscribe(isMobile => {
      this.mobile = isMobile;
      if (this.mobile)
        this.displayedColumns = ['marcaDesc', 'modeloDesc', 'fichaTipoMotor', 'actions'];
      else
        this.displayedColumns = ['marcaDesc', 'modeloDesc', 'fichaTipoMotor', 'caja', 'actions'];
    });
  }

  ngOnDestroy(): void {
    this.vehicleService.paginator = {pg: 1, pgSize: 10, count: 0, pages: 1};
    this.subsVehicle.unsubscribe();
    this.paginatorService.unsubscribe();
  }

  reload() {
    this.vehicleService.paginator = {pg: 1, pgSize: 10, count: 0, pages: 1};
    this.applyFilter();
    this.activeFilter = null;
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.vehicleService.loading.subscribe((load: boolean) => {
      this.loading = load;
    });
    this.vehicleService.lastIndex.subscribe((id: number) => {
      this.lastIndexToGenerated = id;
    });
    this.subsVehicle = this.vehicleService
      .vehicles
      .subscribe((items: any) => {
          if (this.renderInit) {
            this.data = items;
            this._data = items;
            this.initialData();

          }

        },
        error => {
          this.loading = false;
          this.data = [];
          this._data = [];
        });
    this.paginatorService = this.vehicleService
      .paginator
      .subscribe((pagin: any) => {
        if (!isNullOrUndefined(pagin)) {
          this.page = +pagin.pg;
          this.pageSize = +pagin.pgSize;
          this.count = +pagin.count;
          this.pages = +pagin.pages;
        }

      });
    this.applyFilter();
    // this.vehicleService.index();

  }


  ngAfterViewInit(): void {
    this.renderInit = true;
  }

  selectedRow(row) {
    console.log('ROW SELECTED ', row);
    this.showDialogAdd(row);
  }

  public paginatorChange(ev) {
    this.page = ev.pageIndex + 1;
    this.pageSize = ev.pageSize;
    this.applyFilter();
  }

  txtFilter() {
    this.filter = [
      {property: 'fichaMatricula', operator: 'LIKE', value: this.textSearch},
      {property: 'fichaModelo', operator: 'LIKE ANY', value: this.textSearch},
      {property: 'fichaTipoMotor', operator: 'LIKE ANY', value: this.textSearch},
      {property: 'caja', operator: 'LIKE ANY', value: this.textSearch}
    ];
    // 'marcaDesc', 'modeloDesc', 'fichaTipoMotor', 'caja'
    if (this.textSearch !== '') {
      this.data = this.data.filter(f => ((!isNullOrUndefined(f.marcaDesc)) && f.marcaDesc.toLowerCase().indexOf(this.textSearch) != -1) ||
        ((!isNullOrUndefined(f.modeloDesc)) && f.modeloDesc.toLowerCase().indexOf(this.textSearch) != -1) ||
        ((!isNullOrUndefined(f.fichaTipoMotor)) && f.fichaTipoMotor.toLowerCase().indexOf(this.textSearch) != -1) ||
        ((!isNullOrUndefined(f.caja)) && f.caja.toLowerCase().indexOf(this.textSearch) != -1)
      );
      this.initialData();
    } else {
      this.data = this._data;
      this.initialData();
    }

    // this.applyFilter(this.filter);
  }

  public applyFilter(filter: Array<any> = null, page: number = this.page, pageSize: number = this.pageSize) {
    this.vehicleService.index(true, page, pageSize, filter);
  }

  public initialData() {
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const subscribe = timer(200).subscribe(val => this.loading = false);

  }

  public showDialogAdd(entity: VehicleClass = new VehicleClass(), action: string = 'add') {
    const config = new MatDialogConfig();
    config.width = (this.mobile) ? '100%' : '60%';
    config.height = (this.mobile) ? '100%' : '85%';
    this.dialogAdd = this.dialog.open(AppDialogAddComponent, config);
    //todo esto es para optimizar lo del id pero solo cuando es adicionar
    if (action === 'add')
      entity.form.get('id').setValue(this.lastIndexToGenerated);
    if (action === 'detail')
      this.dialogAdd.componentInstance.formDisable = true;
    this.dialogAdd.componentInstance.entity = entity;

    this.dialogAdd.afterClosed().subscribe(result => {
      if (result)
        this.vehicleService.index();
    });
  }

  public showDialogFilter(entity: VehicleClass = new VehicleClass()) {
    const config = new MatDialogConfig();
    config.width = (this.mobile) ? '100%' : '50%';
    config.height = (this.mobile) ? '100%' : '80%';
    config.height = (entity instanceof EstanteriaClass) ? 'auto' : config.height;
    this.dialogSearch = this.dialog.open(AppDialogSearchComponent, config);
    this.dialogSearch.componentInstance.entity = entity;
    this.dialogSearch.afterClosed().subscribe(result => {
      if (result) {
        this.activeFilter = result;
      }
    });
  }

  btnAction(ev) {
    if (ev === 'add')
      this.showDialogAdd();
  }
}
