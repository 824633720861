import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class MotorClass {
    form: FormGroup;
    public id: number = null;
    public alternador:boolean = null;
    public arranque:boolean = null;
    public caudaliMetro:boolean = null;
    public centralita:boolean = null;
    public bombaDir:boolean = null;
    public bombaFreno:boolean = null;
    public servofreno:boolean = null;
    public bombaAforador:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            alternador: new FormControl({value: this.alternador, disabled: false}),
            arranque: new FormControl({value: this.arranque, disabled: false}),
            caudaliMetro: new FormControl({value: this.caudaliMetro, disabled: false}),
            centralita: new FormControl({value: this.centralita, disabled: false}),
            bombaDir: new FormControl({value: this.bombaDir, disabled: false}),
            bombaFreno: new FormControl({value: this.bombaFreno, disabled: false}),
            servofreno: new FormControl({value: this.servofreno, disabled: false}),
            bombaAforador: new FormControl({value: this.bombaAforador, disabled: false})
        });
    }
}
