import {Component, OnInit} from '@angular/core';
import {AppUtils} from '../../shared/helpers/app.utils';
import {AuthGuard} from '../../shared/guard/auth.guard';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../shared/services/auth/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public loading = false;
  public form: FormGroup = new FormGroup({
    username: new FormControl({value: '', disabled: false},
      Validators.compose([
        Validators.required,
        Validators.minLength(2),
        // Validators.pattern(this.emailRegex)
      ])),
    password: new FormControl({value: '', disabled: false},
      Validators.compose([
        Validators.required,
        Validators.minLength(1)
      ]))
  });

// convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  constructor(private auth: AuthGuard,
              public snackBar: MatSnackBar,
              public router: Router,
              public authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log('AUTORIZATED');
          this.loadDataCurrentUser();
        },
        error => {
          this.loading = false;
          this.snackBar.open(error || 'Usuario o contraseña incorrecto', 'x', <MatSnackBarConfig>{duration: 4000});
        });
  }

  //TODO LOAD CURRENT USER DATA
  async loadDataCurrentUser() {
    const data = await this.authenticationService.loadDataCurrent(this.f.username.value);
    this.loading = false;
    console.log('USER LOGIN DATA -> ', AppUtils.load('currentUserData'));
    this.router.navigate(['']);
  }

}
