import {NgModule} from '@angular/core';
import {MaterialModule} from './modules/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoadingPlaceholderComponent} from './components/loading-placeholder/loading-placeholder.component';
import {CapitalizeFirstPipe} from './pipes/capitalize-first.pipe';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {AuthGuard} from './guard/auth.guard';
import {VehicleService} from './services/vehicle.service';
import {CacheService} from './services/cache.service';
import {NavigationService} from './services/navigation.service';
import {AppDialogAddComponent} from './components/app-dialog-add/app-dialog-add.component';
import {AppNomAutocompleteComponent} from './components/app-nom-autocomplete/app-nom-autocomplete.component';
import {NomService} from './services/nom.service';
import {ConfigService} from './services/config.services';
import {HttpClientModule} from '@angular/common/http';
import {PartService} from './services/part.service';
import { AppDialogSearchComponent } from './components/app-dialog-search/app-dialog-search.component';
import {AppImageUploadComponent} from './components/app-image-upload/app-image-upload.component';
import {AppDialogViewImageComponent} from './components/app-dialog-view-image/app-dialog-view-image.component';
import { AppCustomOptionSelectComponent } from './components/app-custom-option-select/app-custom-option-select.component';

const components = [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    LoadingPlaceholderComponent,
    AppNomAutocompleteComponent,
    AppImageUploadComponent,
    AppCustomOptionSelectComponent
];

@NgModule({
    providers: [
        AuthGuard,
        NavigationService,
        VehicleService,
        PartService,
        CacheService,
        NomService,
        ConfigService],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        RouterModule,
        LazyLoadImageModule,
        HttpClientModule
    ],
    declarations: [
        ...components,
        CapitalizeFirstPipe,
        AppDialogAddComponent,
        AppDialogSearchComponent,
        AppDialogViewImageComponent

    ],
    entryComponents: [
        AppDialogAddComponent,
        AppDialogSearchComponent,
        AppDialogViewImageComponent
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        CapitalizeFirstPipe,
        LazyLoadImageModule,
        HttpClientModule,
        ...components,
    ]
})

export class SharedModule {
}
