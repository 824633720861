import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PagesComponent} from './pages.component';
import {HomePageComponent} from './home-page/home-page.component';
import {Error404PageComponent} from './error404-page/error404-page.component';
import {AuthGuard} from '../shared/guard/auth.guard';


const routes: Routes = [
    {
        path: 'inicio',
        component: PagesComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '',component: HomePageComponent, pathMatch: 'full'},
            {path: '404', component: Error404PageComponent}

        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
