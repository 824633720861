import {isArray, isNullOrUndefined} from "util";
import {BehaviorSubject, Observable, Subject} from "rxjs";

export class AppUtils {


  public static logout() {
    AppUtils.clearLocalStorage();
  }

  public static clearLocalStorage() {
    const appKeys: Array<any> = AppUtils.load('appKeys') || [];
    if (appKeys.length) {
      appKeys.map(key => {
        AppUtils.del(key);
      });
    }
    AppUtils.del('appKeys');
  }

  public static save(key, val) {
    const oldVal = AppUtils.load(key);
    localStorage.setItem(btoa(key), btoa(JSON.stringify(val)));
    let appKeys: Array<any> = AppUtils.load('appKeys') || [];
    if (isNullOrUndefined(appKeys))
      appKeys = [];
    if (appKeys.indexOf(key) === -1)
      appKeys.push(key);
    localStorage.setItem(btoa('appKeys'), btoa(JSON.stringify(appKeys)));
    return true;
  }

  public static load(key, defaultValue = null) {
    try {
      return JSON.parse(atob(localStorage.getItem(btoa(key))));
    } catch (e) {
      return defaultValue;
    }
  }

  public static del(key) {
    // localStorage.removeItem(CryptoJS.SHA512(key).toString());
    localStorage.removeItem(btoa(key));
  }



}
