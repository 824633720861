import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy, HostBinding} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Observable, Subscription, timer} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {MatDialog, MatDialogConfig, MatDialogRef, MatSort} from '@angular/material';
import {NavigationService} from '../../shared/services/navigation.service';
import {AppDialogAddComponent} from '../../shared/components/app-dialog-add/app-dialog-add.component';
import {EstanteriaService} from '../../shared/services/estanteria.service';
import {PartClass} from '../../shared/interfaces/part-class';
import {AppDialogSearchComponent} from '../../shared/components/app-dialog-search/app-dialog-search.component';
import {PartTypeService} from '../../shared/services/part-type.service';
import {PartsTypeClass} from '../../shared/interfaces/parts-type-class';
import {AppUtils} from '../../shared/helpers/app.utils';

@Component({
    selector: 'app-parts-types',
    templateUrl: './parts-types.component.html',
    styleUrls: ['./parts-types.component.scss']
})
export class PartsTypesComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = ['id', 'descripcion', 'actions'];
    dataSource: MatTableDataSource<any>;
    subsVehicle: Subscription;
    paginatorService: Subscription;
    loading = true;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @HostBinding('class.mobile') mobile;
    data: any[] = [];
    renderInit = false;
    public filter: Array<any> = [];
    public page = 1;
    public pageSize = 10;
    public pageIndex = 1;
    public pages = 1;
    public count = 0;
    public textSearch = '';

    get isMobile(): Observable<boolean> {
        return this.navigation.obsIsMobile;
    }

    dialogAdd: MatDialogRef<AppDialogAddComponent>;
    dialogSearch: MatDialogRef<AppDialogSearchComponent>;
  public currentUserData:any;

    constructor(public dialog: MatDialog,
                private partTypeService: PartTypeService,
                private navigation: NavigationService) {
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.currentUserData = AppUtils.load('currentUserData');
        this.isMobile.subscribe(isMobile => {
            this.mobile = isMobile;
        });
    }

    ngOnDestroy(): void {
        this.subsVehicle.unsubscribe();
        this.paginatorService.unsubscribe();
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
        this.partTypeService.loading.subscribe((load: boolean) => {
            this.loading = load;
        });
        this.subsVehicle = this.partTypeService
            .parttypes
            .subscribe((items: any) => {

                    if (this.renderInit) {
                        this.data = items;
                        this.initialData();

                    }

                },
                error => {
                    this.loading = false;
                    this.data = [];
                });
        this.paginatorService = this.partTypeService
            .paginator
            .subscribe((pagin: any) => {
                if (!isNullOrUndefined(pagin)) {
                    this.page = +pagin.pg;
                    this.pageSize = +pagin.pgSize;
                    this.count = +pagin.count;
                    this.pages = +pagin.pages;
                }

            });
        this.applyFilter();
        // this.partTypeService.index();

    }


    ngAfterViewInit(): void {
        this.renderInit = true;
    }

    selectedRow(row) {
        console.log('ROW SELECTED ', row);
        this.showDialogAdd(row);
    }

    public paginatorChange(ev) {
        this.page = ev.pageIndex + 1;
        this.pageSize = ev.pageSize;
        this.applyFilter();
    }

    txtFilter() {
        this.filter = [{'property': 'descripcion', 'operator': 'LIKE', 'value': this.textSearch}];
        this.applyFilter(this.filter);
    }

    public applyFilter(filter: Array<any> = null, page: number = this.page, pageSize: number = this.pageSize) {

        this.partTypeService.index(true, page, pageSize, filter);
    }

    public initialData() {
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        const subscribe = timer(200).subscribe(val => this.loading = false);

    }

    public showDialogAdd(entity: PartsTypeClass = new PartsTypeClass(), formDisable: boolean = false) {
        const config = new MatDialogConfig();
        config.width = (this.mobile) ? '100%' : '45%';
        config.height = 'auto';
        this.dialogAdd = this.dialog.open(AppDialogAddComponent, config);
        this.dialogAdd.componentInstance.entity = entity;
        this.dialogAdd.componentInstance.formDisable = formDisable;
        this.dialogAdd.afterClosed().subscribe(result => {
            if (result)
                this.partTypeService.index();
        });
    }
    public showDialogFilter(entity: PartsTypeClass = new PartsTypeClass()) {
        const config = new MatDialogConfig();
        config.width = ( this.mobile) ? '100%' : '50%';
        config.height = ( this.mobile) ? '100%' : '80%';
        config.height = ( entity instanceof PartsTypeClass) ? 'auto' : config.height;
        this.dialogSearch = this.dialog.open(AppDialogSearchComponent, config);
        this.dialogSearch.componentInstance.entity = entity;
        this.dialogSearch.afterClosed().subscribe(result => {
            // if(result )
            //     this.navigateToRoute(entity);

        });
    }

    btnAction(ev) {
        if (ev === 'add')
            this.showDialogAdd();
    }
}
