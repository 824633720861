import {Injectable} from '@angular/core';
import {ConfigService} from './config.services';
import {CacheService} from './cache.service';
import {HttpClient} from '@angular/common/http';
import {isNullOrUndefined} from 'util';
import {BehaviorSubject, Observable} from 'rxjs';
import {PartClass} from '../interfaces/part-class';
import {Part} from '../interfaces/part';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  public httpOptions: any;
  private PART: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  private PAGE: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private PAGINATOR: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private LOADING: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private LASTINDEX: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  get loading() {
    return this.LOADING.asObservable();
  }


  get parts() {
    return this.PART.asObservable();
  }

  get page() {
    return this.PAGE.asObservable();
  }

  get paginator() {
    return this.PAGINATOR.asObservable();
  }
  get lastIndex() {
    return this.LASTINDEX.asObservable();
  }

  constructor(private http: HttpClient,
              public cacheData: CacheService,
              public configService: ConfigService) {
    this.httpOptions = this.configService.getHttpOptions();
  }

  /**
   * Este método es para obtener todos los vehículos registrados
   * @param {number} pg  : Página actual
   * @param {number} pageSize  : Cantidad de elementos por página
   * @param {Array[any]} filter  : Arreglo de elementos para filtrar por cualquier atributo de la entidad
   * @param {boolean} clearCache  : Esto es para limpiar la cache.Siempre q se agregue o elimine o modifique algo
   * de un usuario este parametro debe pasarse verdadero para que vuelva a pedirlo al server
   */

  public index(clearCache: boolean = false, page: number = 1, pageSize: number = 10, filter: Array<any> = null) {
    this.LOADING.next(true);
    if (clearCache)
      this.clearCache();
    const size = Math.max(5, pageSize);
    const params = [
      `pg=${page}`,
      `pgSize=${size}`
    ];
    if (!isNullOrUndefined(filter))
      params.push(`filter=${JSON.stringify(filter)}`);
    const query = params.join('&');
    let url = encodeURI(`${this.configService.config.urls.parts}\?${query}`);
    url = url.replace(/\+/g, '%2b');
    const inCache: any = this.cacheData.get(this.configService.config.urls.parts);
    if (isNullOrUndefined(inCache)) {
      const rq = this.http.get(url).subscribe(
        (list: any) => {
          let part: Array<PartClass> = [];
          if (!isNullOrUndefined(list)) {
            if (list.data.length > 0)
              this.LASTINDEX.next(list.data[0].id + 1);
            else
              this.LASTINDEX.next(null);
            list.data.map(p => {
              const obj = new PartClass(
                p.id,
                p.idVehicleDm,
                p.relocation,
                p.descripcion,
                p.fichaAnyo,
                p.fechaInventariado,
                p.fechaSalida,
                p.ventaPrecioCliente,
                p.ventaPrecioProfesional,
                p.ventaPrecioReal,
                p.imagenUrl,
                p.imagenSmallThumbnailUrl,
                p.imagenAlbumId,
                p.imagenId,
                p.bajaLogica,
                !(isNullOrUndefined(p.zzPart)) ? p.zzPart.id : null,
                !(isNullOrUndefined(p.idEstanteria)) ? p.idEstanteria.id : null,
                !(isNullOrUndefined(p.fichaMarca)) ? p.fichaMarca.id : null,
                !(isNullOrUndefined(p.fichaModelo)) ? p.fichaModelo.id : null,
                !(isNullOrUndefined(p.idVehicleDmRelocated)) ? p.idVehicleDmRelocated.id : null,
                p.color
              );
              obj.txtEstanteria = !(isNullOrUndefined(p.idEstanteria)) ? p.idEstanteria.descripcion : null;
              obj.piezaDesc = !(isNullOrUndefined(p.zzPart)) ? p.zzPart.descripcion : '';
              obj.marcaDesc = !(isNullOrUndefined(p.fichaMarca)) ? p.fichaMarca.descripcion : '';
              obj.modeloDesc = !(isNullOrUndefined(p.fichaModelo)) ? p.fichaModelo.descripcion : '';
              part.push(obj);
            });
            this.PAGINATOR.next({
              pg: list.pg,
              pgSize: list.pgSize,
              count: list.count,
              pages: list.pages
            });
          }
          this.PART.next(part);
          this.LOADING.next(false);

        },
        (error: any) => { // on error
          console.error('Vehicles Service:', error);
          this.PART.next([]);
          this.LOADING.next(false);
        }
      );
    } else {
      this.PART.next(inCache);
      this.LOADING.next(false);
    }

  }

  public lastIdGenerated(): Promise<number> {
    const params = [
      `pg=${1}`,
      `pgSize=${1}`
    ];
    const query = params.join('&');
    let url = encodeURI(`${this.configService.config.urls.parts}\?${query}`);
    url = url.replace(/\+/g, '%2b');
    let id: number = null;
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        (list: any) => {
          if (!isNullOrUndefined(list)) {
            id = list.data[0].id + 1;
            this.LASTINDEX.next(id);
            resolve(id);
          }
        },
        (error: any) => { // on error
          reject(id);
        }
      );
      return id;
    });


  }

  public filter(filter: Array<any> = null) {
    this.LOADING.next(true);
    const inCache: any = this.cacheData.get(this.configService.config.urls.parts + '-POST');
    if (isNullOrUndefined(inCache)) {
      const rq = this.http.post(this.configService.config.urls.parts, {'filter': filter}, this.httpOptions).subscribe(
        (list: any) => {
          let part: Array<PartClass> = [];
          if (!isNullOrUndefined(list)) {
            list.data.map(p => {
              const obj = new PartClass(
                p.id,
                p.idVehicleDm,
                p.relocation,
                p.descripcion,
                p.fichaAnyo,
                p.fechaInventariado,
                p.fechaSalida,
                p.ventaPrecioCliente,
                p.ventaPrecioProfesional,
                p.ventaPrecioReal,
                p.imagenUrl,
                p.imagenSmallThumbnailUrl,
                p.imagenAlbumId,
                p.imagenId,
                p.bajaLogica,
                !(isNullOrUndefined(p.zzPart)) ? p.zzPart.id : null,
                !(isNullOrUndefined(p.idEstanteria)) ? p.idEstanteria.id : null,
                !(isNullOrUndefined(p.fichaMarca)) ? p.fichaMarca.id : null,
                !(isNullOrUndefined(p.fichaModelo)) ? p.fichaModelo.id : null,
                !(isNullOrUndefined(p.idVehicleDmRelocated)) ? p.idVehicleDmRelocated.id.id : null,
                p.color
              );
              obj.txtEstanteria = !(isNullOrUndefined(p.idEstanteria)) ? p.idEstanteria.descripcion : null;
              obj.piezaDesc = !(isNullOrUndefined(p.zzPart)) ? p.zzPart.descripcion : '';
              obj.marcaDesc = !(isNullOrUndefined(p.fichaMarca)) ? p.fichaMarca.descripcion : '';
              obj.modeloDesc = !(isNullOrUndefined(p.fichaModelo)) ? p.fichaModelo.descripcion : '';
              part.push(obj);
            });
            this.PAGINATOR.next({
              pg: list.pg,
              pgSize: list.pgSize,
              count: list.count,
              pages: list.pages
            });
          }
          this.PART.next(part);
          this.LOADING.next(false);

        },
        (error: any) => { // on error
          console.error('Parts Service:', error);
          this.PART.next([]);
          this.LOADING.next(false);
        }
      );
    } else {
      this.PART.next(inCache);
      this.LOADING.next(false);
    }

  }

  public create(data: any): Observable<any> {
    return this.http.post(this.configService.config.urls.parts + 'new', data, this.httpOptions);
  }

  public update(data: PartClass): Observable<any> {
    return this.http.put(this.configService.config.urls.parts + 'update/' + data.id, data, this.httpOptions);
  }

  public delete(id): Observable<any> {
    return this.http.delete(this.configService.config.urls.parts + 'delete/' + id);
  }

  /**
   * Este método es para limpiar la cache. O sea si ya pedí algo y no ha cambiado lo guardo
   * en la cache del navegador y antes de repetir la petición lo cojo de la cache
   */
  clearCache() {
    this.cacheData.clearReq(this.configService.config.urls.parts);
  }
}
