import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {RoutesConfig} from './configs/routes.config';
import {Error404PageComponent} from './pages/error404-page/error404-page.component';
import {PagesComponent} from './pages/pages.component';
import {AuthGuard} from './shared/guard/auth.guard';
import {LoginComponent} from './auth/login/login.component';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {VehiclesComponent} from './pages/vehicles/vehicles.component';
import {PartsComponent} from './pages/parts/parts.component';
import {EstanteriasComponent} from './pages/estanterias/estanterias.component';
import {PartsTypesComponent} from './pages/parts-types/parts-types.component';
const routesNames = RoutesConfig.routesNames;
// redirectTo: 'auth/login',
const routes: Routes = [
    { path: '', redirectTo: routesNames.home, pathMatch: 'full' },
    {path: 'auth/login', component: LoginComponent},
    {path: routesNames.home, component: HomePageComponent, canActivate: [AuthGuard] },
    {path: routesNames.vehicles, component: VehiclesComponent, canActivate: [AuthGuard] },
    {path: routesNames.parts, component: PartsComponent, canActivate: [AuthGuard] },
    {path: routesNames.partstypes, component: PartsTypesComponent, canActivate: [AuthGuard] },
    {path: routesNames.shelves, component: EstanteriasComponent, canActivate: [AuthGuard] },
    {path: routesNames.error404, component: Error404PageComponent},
    // otherwise redirect to 404
    {path: '**', redirectTo: RoutesConfig.routes.error404}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
