import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../services/auth/authentication.service';
import {AppUtils} from '../helpers/app.utils';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //TODO AUTH-GUARD RELOAD PAGE AUTENTICATE
    const currentUser = AppUtils.load('currentUser');
    console.log('AUTH-GUARD',currentUser);
    if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['auth/login']);
    return false;
  }
}



// import {Injectable} from '@angular/core';
// import {Router, CanActivate} from '@angular/router';
// import {AppUtils} from '../helpers/app.utils';
// import {isNullOrUndefined} from 'util';
// import {HttpClient} from '@angular/common/http';
// import {CacheService} from '../services/cache.service';
// import {ConfigService} from '../services/config.services';
// import {AppConfig} from '../../configs/app.config';
// import {Observable} from 'rxjs';
//
// @Injectable()
// export class AuthGuard implements CanActivate {
//     config = AppConfig;
//     public httpOptions: any;
//
//     constructor(public router: Router, private http: HttpClient,public configService: ConfigService) {
//         this.httpOptions = this.configService.getHttpOptions();
//     }
//
//     canActivate() {
//         const user = AppUtils.load('currentUser');
//         console.log('USER ', user);
//         if (!isNullOrUndefined(user)) {
//             return true;
//         }
//         else
//             this.router.navigate(['/auth/login']);
//         return false;
//     }
//     public login(user: any): Observable<any> {
//         return this.http.post(this.config.urls.login , user, this.httpOptions);
//     }
// }
