import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
    public static smallViewportWidth = 600;
    public static largeViewportWidth = 992;
    public static mediumScreenAndDownSubject = new BehaviorSubject<boolean>(false);
    private static wresizeListened = false;
  constructor() { }
    public get obsIsMobile(): Observable<boolean> {
        if (window !== undefined) {
            const isMobile = window.matchMedia(`(max-width: ${NavigationService.largeViewportWidth}px)`).matches;
            if (isMobile !== NavigationService.mediumScreenAndDownSubject.getValue())
                NavigationService.mediumScreenAndDownSubject.next(isMobile);
            if (!NavigationService.wresizeListened) {
                NavigationService.wresizeListened = true;
                window.addEventListener('resize', () => {
                    const be = window.matchMedia(`(max-width: ${NavigationService.largeViewportWidth}px)`).matches;
                    if (be !== NavigationService.mediumScreenAndDownSubject.getValue())
                        NavigationService.mediumScreenAndDownSubject.next(be);
                });
            }
        }
        return NavigationService.mediumScreenAndDownSubject.asObservable();
    }

    public get mediumScreenAndDown(): boolean {
        return window !== undefined
            ? window.matchMedia(`(max-width: ${NavigationService.largeViewportWidth}px)`).matches
            : false;
    }

    public get mediumScreenAndUp(): boolean {
        return window !== undefined ? window.matchMedia(`(min-width: ${NavigationService.smallViewportWidth}px)`).matches : false;
    }

    public get smallScreen(): boolean {
        return window !== undefined ? window.matchMedia(`(max-width: ${NavigationService.smallViewportWidth}px)`).matches : false;
    }

    public get mediumScreen(): boolean {
        return window !== undefined ? (!this.smallScreen && !this.largeScreen) : false;
    }

    public get largeScreen(): boolean {
        return window !== undefined ? window.matchMedia(`(min-width: ${NavigationService.largeViewportWidth}px)`).matches : false;
    }
}
