import {InjectionToken} from '@angular/core';

// export let APP_CONFIG = new InjectionToken('app.config');
const baseUrl = 'https://api.desguacesmutilva.com/api/';
export const AppConfig: any = {
    baseUrl:baseUrl,
    votesLimit: 3,
    topHeroesLimit: 5,
    snackBarDuration: 3000,
    cspDirectives: {
        defaultSrc: [
            '\'self\'',
            'http://*.google-analytics.com',
            'http://www.googletagmanager.com',
            'https://*.google.com',
            'https://*.google-analytics.com',
            'https://*.googletagmanager.com',
            'https://*.gstatic.com',
            'https://*.googleapis.com',
            'https://authedmine.com',
            'https://az743702.vo.msecnd.net',
            'https://sentry.io',
            'ws://localhost:4100',
        ],
        styleSrc: [
            '\'self\'',
            '\'unsafe-inline\'',
            'https://*.googleapis.com'
        ],
        scriptSrc: [
            '\'self\'',
            '\'unsafe-inline\'',
            'http://*.googletagmanager.com',
            'https://*.google-analytics.com'
        ]
    },
    urls: {
        login: baseUrl + 'auth/login',
        vehicles: baseUrl + 'tbvehicle/',
        parts: baseUrl + 'tbpart/',
        marca: baseUrl + 'tbzzmarca/',
        modelo: baseUrl + 'tbzzmodelo/',
        grua: baseUrl + 'tbzzgrua/',
        estanterias: baseUrl + 'tbzzestanteria/',
        zzpart: baseUrl + 'tbzzpart/',
        procedencia: baseUrl + 'tbzzprocedencia/',
        vehiculovariante: baseUrl + 'tbzzvehiculovariante/',
        vehiculotipo: baseUrl + 'tbzzvehiculotipo/',
        vehiculorehubicado: baseUrl + 'tbvehicledm/'
    }
};
