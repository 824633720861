import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CacheService} from './cache.service';
import {isNullOrUndefined} from 'util';
import {AppConfig} from '../../configs/app.config';
import {NomModel} from '../interfaces/nomModel';
import {ConfigService} from './config.services';

@Injectable()
export class NomService {
    listCached:Array<string> = [];

    constructor(public http: HttpClient, public cacheData: CacheService, public configService: ConfigService) {
    }

    getAll(nom: string, filter: any = null): any {
        // {headers: this.config.getHeaders()}
        return new Promise((resolve, reject) => {
            const params = [
                `pg=${1}`,
                `pgSize=${10000}`
            ];
            if (!isNullOrUndefined(filter))
                params.push(`filter=${JSON.stringify([filter])}`);
            const query = params.join('&');
            let url = encodeURI(`${nom}\?${query}`);
            let data: any = [];
            let result: any = this.cacheData.get(url);
            if (isNullOrUndefined(result)) {
                result = [];
                const request = this.http.get(url);
                if (request) {
                    return request
                        .subscribe((responseData: any) => {
                            data = responseData.data;
                            data.map(element => {
                                // todo VALID tbvehicledm
                                if (nom.indexOf('/tbvehicledm') !== -1) {
                                    result.push(
                                        new NomModel(element.id.id,
                                            'Matrícula: ' + element.id.fichaMatricula + ' / ' + 'Tipo de motor: ' + element.id.fichaTipoMotor,
                                            element.descripcionFormularios || null)
                                    );
                                }
                                else {
                                    result.push(
                                        new NomModel(element.id, element.descripcion, element.descripcionFormularios || null)
                                    );
                                }


                            });
                            this.cacheData.set(url, result);
                            this.listCached.push(url);
                            resolve(result);
                            // console.log('REQUEST ' + nom, result);
                        }, (error: any) => {
                            resolve(null);
                        });
                }

            }
            resolve(result);

        });


    }

    reloadData(key: string) {
        // this.cacheData.clear('nom-' + key);
        this.getAll(key);
    }
    clearAllCache(){
        this.listCached.map(c =>{
            this.cacheData.clear(c);
        });
        this.listCached = [];
    }

}
