import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";

@Injectable()
export class CacheService {
    protected cachedTime = 1000 * 60 * 60 * 24;

    constructor() {
    }

    public cache = new Map();
    public cacheReq = new Map();

    set(key: string, data: any) {
        this.cache.set(key, data);
        return data;
    }

    setReq(url: string, data: any) {
        let cachedData = new ReplaySubject(1);
        cachedData.next(data);
        this.cacheReq.set(url, cachedData);
        return cachedData;
    }

    get(key: string) {
       return this.cache.get(key);
    }

    getReq(key: string) {
       return this.cacheReq.get(key);
    }

    clear(key?: string) {
        if (key == null)
            this.cache.clear();
        else
            this.cache.delete(key);
    }

    clearReq(key?: string) {
        if (key == null)
            this.cacheReq.clear();
        else
            this.cacheReq.delete(key);


    }
}
