import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '../config.services';
import {User} from '../../interfaces/user';
import {AppUtils} from '../../helpers/app.utils';
import {isNullOrUndefined} from 'util';


@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, public configService: ConfigService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public  login(username: string, password: string) {
    return this.http.post<any>(`${this.configService.config.urls.login}`, {username, password})
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('currentUser', JSON.stringify(user));
        AppUtils.save('currentUser', user);
        console.log('USER LOGIN -> ', user);
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    AppUtils.del('currentUser');
    AppUtils.del('currentUserData');
    this.currentUserSubject.next(null);
  }


  public loadDataCurrent(user: string): Promise<any> {
    const filter = [{'property': 'username', 'operator': 'LIKE', 'value': user}];
    const promise = new Promise((resolve, reject) => {
      this.http.post(this.configService.config.urls.auth, {'filter': filter})
        .toPromise()
        .then((res: any) => {
            // Success
            if (!isNullOrUndefined(res)) {
              const current = res.data[0];
              AppUtils.save('currentUserData', current);
            }
            resolve();
          },
          err => {
            // Error
            console.error('ERROR AUTH Service:', err);
            reject(err);
          }
        );
    });
    return promise;
  }
}
