/**
 * Created by JLRH on 17/10/2019.
 */
import {Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {AppDialogAddComponent} from '../app-dialog-add/app-dialog-add.component';
import {AppDialogViewImageComponent} from '../app-dialog-view-image/app-dialog-view-image.component';

@Component({
    selector: 'app-image-upload',
    templateUrl: './app-image-upload.component.html',
    styleUrls: ['./app-image-upload.component.scss']
})
export class AppImageUploadComponent implements OnInit {
    @ViewChild('uploadFile', {static: true}) protected fileUpload: ElementRef;
    // showClass:string = 'visibility-off';
    selectIconClass: string = 'right';
    showDelete: boolean = false;
    reader = new FileReader();
    inLoad: boolean = false;
    @Input() rowMode: boolean = true;
    @Input() title: string = 'Seleccionar imagen';
    @Input() defaultPicture: string = '';
    @Input() circular: boolean = true;
    @Input() base64: boolean = true;
    @Input() changeImage: boolean = true;
    @Input() custom_width: number = 200;
    @Input() custom_height: number = 200;
    @Input() unity: string = 'px';
    @Output() srcChange: EventEmitter<any> = new EventEmitter();
    @Output() srcChangeFile: EventEmitter<File> = new EventEmitter();
    @Input() photo: string = '../../../../assets/images/upload.svg';
    @Input() showButtons: true;
    tempFile = null;
    dontImage = true;
    dialogView: MatDialogRef<AppDialogViewImageComponent>;

    constructor(public _snackBar: MatSnackBar, public dialog: MatDialog) {

    }

    ngOnInit() {
        if (this.photo == '' || isNullOrUndefined(this.photo)) {
            this.photo = '../../../../assets/images/upload.svg';
            this.dontImage = true;
        }
        else
            this.dontImage = false;


        let file = this.fileUpload.nativeElement;
        this.reader.addEventListener('load', (event: Event) => {
            this.inLoad = !this.inLoad;
            this.photo = (<any> event.target).result;
            this.dontImage = false;
            if (this.base64)
                this.srcChange.emit(this.photo);
            else {
                const data = {
                    filename: this.tempFile.name,
                    filetype: this.tempFile.type,
                    value: this.reader.result.toString().split(',')[1]
                };
                this.srcChange.emit(data);

            }

        }, false);
    }

    public onFiles($event): void {
        let file = $event.target.files; //4797056 //2082857
        if (file.length > 0) {
            if (file[0].size < 8000000) {
                let files = $event.target.files[0];
                this.tempFile = files;
                this.srcChangeFile.emit(files);
                this.reader.readAsDataURL(files);
            }
            else {
                this._snackBar.open('Tamaño excedido (hasta 8 mg)', 'X', <MatSnackBarConfig>{duration: 3000});
            }
        }


    }

    public onloadPhoto(): void {
        if (this.changeImage) {
            this.inLoad = !this.inLoad;
            const file = this.fileUpload.nativeElement;
            file.click();
        }

    }
    public deletePhoto(){
      this.photo = null;
      this.dontImage = true;
      this.srcChange.emit(null);
    }

    public showDialogAdd() {
        const config = new MatDialogConfig();
        config.width = '100%';
        config.height = '100%';
        this.dialogView = this.dialog.open(AppDialogViewImageComponent, config);
        this.dialogView.componentInstance.url = this.photo;
        this.dialogView.afterClosed().subscribe(result => {
            // if(result )
        });
    }

}
