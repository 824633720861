import {Component, OnInit} from '@angular/core';
import {PartClass} from '../../interfaces/part-class';
import {EstanteriaClass} from '../../interfaces/estanteria-class';
import {VehicleClass} from '../../interfaces/vehicle-class';
import {AppConfig} from '../../../configs/app.config';
import {FormGroup} from '@angular/forms';
import {EstanteriaService} from '../../services/estanteria.service';
import {PartService} from '../../services/part.service';
import {VehicleService} from '../../services/vehicle.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {isNullOrUndefined} from 'util';
import {Filter} from '../../interfaces/filter-class';
import {AppUtils} from '../../helpers/app.utils';
import {ConfigService} from '../../services/config.services';
import {timer} from 'rxjs';
import {PartsTypeClass} from '../../interfaces/parts-type-class';

declare let $: any, jQuery: any;

@Component({
  selector: 'app-app-dialog-search',
  templateUrl: './app-dialog-search.component.html',
  styleUrls: ['./app-dialog-search.component.scss']
})
export class AppDialogSearchComponent implements OnInit {
  public entity: any;
  public loading = false;
  filter: Array<any> = [];
  public moreData = false;
  public filterInModel: Filter = null;
  public maxDate = moment().toDate();
  filterInVehicles = [
    new Filter('fichaMatricula', 'LIKE'),
    new Filter('fichaMarca', '='),
    new Filter('fichaModelo', '='),
    new Filter('fichaTipo', '='),
    new Filter('fichaVariante', '='),
    new Filter('fichaTipoMotor', 'LIKE'),
    new Filter('fichaAnyo', 'LIKE'),
    new Filter('fichaColor', 'LIKE'),
    new Filter('equipamientoDireccion', 'LIKE'),
    new Filter('equipamientoFarosAntinieblas', 'LIKE'),
    new Filter('equipamientoRetrovisores', 'LIKE'),
    new Filter('equipamientoCierreCentralizado', 'LIKE'),
    new Filter('equipamientoElevalunasDelanteros', 'LIKE'),
    new Filter('equipamientoElevalunasTraseros', 'LIKE'),
    new Filter('equipamientoAbs', 'LIKE'),
    new Filter('equipamientoAireAcondicionado', 'LIKE'),
    new Filter('equipamientoClimatizador', 'LIKE'),
    new Filter('bajaLogica', 'LIKE')
  ];
  filterInParts = [
    new Filter('fichaMarca', '='),
    new Filter('fichaModelo', '='),
    new Filter('zzPart', '='),
    new Filter('idEstanteria', '='),
    new Filter('fichaAnyo', 'LIKE'),
    new Filter('relocation', 'LIKE'),
    new Filter('bajaLogica', 'LIKE')
  ];
  filterInEstanteria = [
    new Filter('descripcion', 'LIKE')
  ];
  typeEntity: string = '';
  arrayFilter: Array<Filter> = [];
  initFormValues = null;

  constructor(public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private vehicleService: VehicleService,
              private partService: PartService,
              public configService: ConfigService,
              private estanteriaService: EstanteriaService) {
    $ = $ || jQuery;
  }

  ngOnInit() {
    //TODO TO FIX AUTOCOMPLETE ERROR IN ANGULAR
    this.autoFocus();
    console.log('ENTITY ',this.entity.form);
    if (this.isInstanceVehicle()) {
      this.arrayFilter = this.filterInVehicles;
      this.typeEntity = 'VEHICLE';
      this.entity.form.get('fechaEntrada').setValue(null);
    }

    if (this.isInstancePart()) {
      this.arrayFilter = this.filterInParts;
      this.typeEntity = 'PART';
    }

    if (this.isInstanceEstanteria()) {
      this.arrayFilter = this.filterInEstanteria;
      this.typeEntity = 'ESTANTERIA';
    }
    // const f = AppUtils.load('FILTER-' + this.typeEntity);
    // if (!isNullOrUndefined(f))
    //   this.entity.form.patchValue(f);

    this.initFormValues = this.entity.form.value;

  }
  changeAutoComplete(key, ev) {
    switch (key) {
      case 'marca':
        this.filterInModel = new Filter('idMarca', '=', ev.id);
    }
  }
  autoFocus() {

    if (this.isInstanceVehicle() || this.isInstancePart()) {
      timer(1500).subscribe(val => $('div.cdk-overlay-pane > div.mat-autocomplete-panel').hide());
    }

  }

  isString(x: any) {
    return typeof x === 'string';
  }

  isBoolean(x: any) {
    return typeof x === 'boolean';
  }

  isNumber(x: any) {
    return typeof x === 'number';
  }

  createFilter(): Array<Filter> {
    const result: Array<Filter> = [];
    const valuesForm = this.entity.form.value;
    // console.log('CREATE FILTER ---->>>>', valuesForm);

    //TODO FIX FILTER WITH MULTIPLE VALUES RELATIONS JOINS
    for (const key in valuesForm) {
      if (valuesForm[key] !== this.initFormValues[key]) {
        if (key.indexOf('Valores') === -1) {
          result.push(new Filter(key, (this.isString(valuesForm[key])) ? 'LIKE' : '=', valuesForm[key]));
        } else {
          const tempKey = key.split('Valores')[0];
          const innerForm = valuesForm[key];
          for (const innerKey in innerForm) {
            if (innerForm[innerKey] !== this.initFormValues[key][innerKey]) {
              result.push(new Filter(tempKey + '.' + innerKey, (this.isString(innerForm[innerKey])) ? 'LIKE' : '=', innerForm[innerKey]));
            }
          }
        }
      }
    }

    return result;
  }

  searchData() {
    let filter = this.createFilter();
    if (this.isInstanceVehicle())
      this.vehicleService.filter(filter);
    if (this.isInstancePart())
      this.partService.filter(filter);
    if (this.isInstanceEstanteria())
      this.estanteriaService.filter(filter);

    AppUtils.save('FILTER-' + this.typeEntity, this.entity.form.value);
    this.dialogRef.close(filter);

  }

  clear() {
    this.entity.form.reset();
    AppUtils.save('FILTER-' + this.typeEntity, this.entity.form.value);
  }

  close() {
    this.dialogRef.close();
    AppUtils.save('FILTER-' + this.typeEntity, this.entity.form.value);
  }

  public isInstanceVehicle() {
    return this.entity instanceof VehicleClass;
  }

  public isInstancePart() {
    return this.entity instanceof PartClass;
  }
  public isInstancePartTypes() {
    return this.entity instanceof PartsTypeClass;
  }

  public isInstanceEstanteria() {
    return this.entity instanceof EstanteriaClass;
  }

}
