import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export function initConfig(config: ConfigService) {
    return () => config.load();
}

@Injectable()
export class ConfigService {
    private headers;
    private httpOptions;
    private _config: any = {
        baseUrl: '',
        urls: {}
    };
    private _baseUrl: string = 'http://localhost:8000/api';

    get config(): any {
        return this._config;
    }

    set config(value: any) {
        this._config = value;
    }

    get baseUrl(): string {
        return this._baseUrl;
    }

    set baseUrl(value: string) {
        this._baseUrl = value;
        this.updateUrls();
    }

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
                // ,'Authorization': 'my-auth-token'
            })
        };
        this.baseUrl = this._baseUrl;
    }

    updateUrls() {
        this._config.urls = {
            auth: this._baseUrl + 'auth/',
            login: this._baseUrl + 'auth/login',
            vehicles: this._baseUrl + 'tbvehicle/',
            parts: this._baseUrl + 'tbpart/',
            marca: this._baseUrl + 'tbzzmarca/',
            modelo: this._baseUrl + 'tbzzmodelo/',
            grua: this._baseUrl + 'tbzzgrua/',
            estanterias: this._baseUrl + 'tbzzestanteria/',
            zzpart: this._baseUrl + 'tbzzpart/',
            procedencia: this._baseUrl + 'tbzzprocedencia/',
            vehiculovariante: this._baseUrl + 'tbzzvehiculovariante/',
            vehiculotipo: this._baseUrl + 'tbzzvehiculotipo/',
            vehiculorehubicado: this._baseUrl + 'tbvehicledm/'
        };
    }

    getHttpOptions(): any {
        return this.httpOptions;
    }

    getHeaders(): HttpHeaders {
        // const currentUser = AppUtils.currentUser();
        // if (currentUser && currentUser.accessToken) {
        //     this.headers.set('X-Api-Key', currentUser.accessToken);
        // // }
        return this.headers;
    }

    /**
     * This  method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        return new Promise((resolve, reject) => {
            let request: any = null;
            switch (environment.env) {
                case 'production': {
                    request = this.http.get('./assets/config/' + environment.env + '.json');
                }
                    break;

                case 'development': {
                    request = this.http.get('./assets/config/' + environment.env + '.json');
                }
                    break;

                case 'default': {
                    console.error('Environment file is not set or invalid');
                    resolve(true);
                }
                    break;
            }

            if (request) {
                request
                    .subscribe((responseData) => {
                        if (responseData) {
                            console.log('RESPONSE DATA ENV', responseData);
                            this.baseUrl = responseData.base_url;
                            console.log('DATA ENV CONFIG', this.config);
                        }

                        resolve(true);
                    });
            } else {
                console.error('Env config file "env.json" is not valid');
                resolve(true);
            }
        });
    }

}
