import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class RetrovisoresClass {
    form: FormGroup;
    public id: number = null;
    public espejoIzdo:boolean = null;
    public espejoDcho:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            espejoIzdo: new FormControl({value: this.espejoIzdo, disabled: false}),
            espejoDcho: new FormControl({value: this.espejoDcho, disabled: false})
        });
    }
}
