import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class TapiceriaClass {
    form: FormGroup;
    public id: number = null;
    public asientoDI:boolean = null;
    public asientoDD:boolean = null;
    public asientoTras:boolean = null;
    public pomo:boolean = null;
    public volante:boolean = null;
    public parasolIzdo:boolean = null;
    public parasolDcho:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            asientoDI: new FormControl({value: this.asientoDI, disabled: false}),
            asientoDD: new FormControl({value: this.asientoDD, disabled: false}),
            asientoTras: new FormControl({value: this.asientoTras, disabled: false}),
            pomo: new FormControl({value: this.pomo, disabled: false}),
            volante: new FormControl({value: this.volante, disabled: false}),
            parasolIzdo: new FormControl({value: this.parasolIzdo, disabled: false}),
            parasolDcho: new FormControl({value: this.parasolDcho, disabled: false})
        });
    }
}
