import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CacheService} from './cache.service';
import {isNullOrUndefined} from 'util';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Vehicle} from '../interfaces/vehicle';
import {VehicleClass} from '../interfaces/vehicle-class';
import {ConfigService} from './config.services';
import {CarroceriaClass} from '../interfaces/carroceria-class';
import {EscapeClass} from '../interfaces/escape-class';
import {LucesClass} from '../interfaces/luces-class';
import {LunasClass} from '../interfaces/lunas-class';
import {RetrovisoresClass} from '../interfaces/retrovisores-class';
import {CalefaccionClass} from '../interfaces/calefaccion-class';
import {InstPuertasClass} from '../interfaces/instPuertas-class';
import {SeguridadClass} from '../interfaces/seguridad-class';
import {TapiceriaClass} from '../interfaces/tapiceria-class';
import {RodajeClass} from '../interfaces/rodaje-class';
import {MotorClass} from '../interfaces/motor-class';
import {CambioClass} from '../interfaces/cambio-class';
import {SeguimientoClass} from '../interfaces/seguimiento-class';
import {EquipamientoClass} from '../interfaces/equipamiento-class';
import {NomModel} from '../interfaces/nomModel';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  public httpOptions: any;
  private VEHICLES: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  private LOADING: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private PAGE: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private LASTINDEX: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private PAGINATOR: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  get vehicles() {
    return this.VEHICLES.asObservable();
  }

  get page() {
    return this.PAGE.asObservable();
  }

  get paginator() {
    return this.PAGINATOR.asObservable();
  }

  set paginator(values: any) {
    const {pg, pgSize, count, pages} = values;
    this.PAGINATOR.next({
      pg,
      pgSize,
      count,
      pages
    });
  }

  get loading() {
    return this.LOADING.asObservable();
  }

  get lastIndex() {
    return this.LASTINDEX.asObservable();
  }

  constructor(private http: HttpClient,
              public cacheData: CacheService,
              public configService: ConfigService) {
    this.httpOptions = this.configService.getHttpOptions();
  }

  /**
   * Este método es para obtener todos los vehículos registrados
   * @param {number} pg  : Página actual
   * @param {number} pageSize  : Cantidad de elementos por página
   * @param {Array[any]} filter  : Arreglo de elementos para filtrar por cualquier atributo de la entidad
   * @param {boolean} clearCache  : Esto es para limpiar la cache.Siempre q se agregue o elimine o modifique algo
   * de un usuario este parametro debe pasarse verdadero para que vuelva a pedirlo al server
   */

  public index(clearCache: boolean = false, page: number = 1, pageSize: number = 10, filter: Array<any> = null) {
    if (clearCache)
      this.clearCache();
    const size = Math.max(5, pageSize);
    const params = [
      `pg=${page}`,
      `pgSize=${size}`
    ];
    if (!isNullOrUndefined(filter))
      params.push(`filter=${JSON.stringify(filter)}`);
    const query = params.join('&');
    let url = encodeURI(`${this.configService.config.urls.vehicles}\?${query}`);
    url = url.replace(/\+/g, '%2b');
    const inCache: any = this.cacheData.get(this.configService.config.urls.vehicles);
    this.LOADING.next(true);
    if (isNullOrUndefined(inCache)) {
      const rq = this.http.get(url).subscribe(
        (list: any) => {
          let vehic: Array<Vehicle> = [];
          if (!isNullOrUndefined(list)) {
            if (list.data.length > 0)
              this.LASTINDEX.next(list.data[0].id + 1);
            else
              this.LASTINDEX.next(null);

            list.data.map(v => {
              const obj = new VehicleClass(
                v.id,
                v.fechaEntrada,
                v.fechaSalida,
                v.fichaMatricula,
                v.fichaTipoMotor,
                v.fichaDescripcion,
                v.fichaAnyo,
                v.fichaKilometros,
                v.fichaColor,
                v.fichaBastidor,
                v.fichaNumeroPuertas,
                v.fichaImagen1Url,
                v.fichaImagen1SmallThumbnailUrl,
                v.fichaImagen1AlbumId,
                v.fichaImagen1Id,
                v.fichaImagen2Url,
                v.fichaImagen2SmallThumbnailUrl,
                v.fichaImagen2AlbumId,
                v.fichaImagen2Id,
                v.ciPrecio,
                v.observaciones,
                v.bajaLogica,
                !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.id : null,
                !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.id : null,
                !(isNullOrUndefined(v.fichaTipo)) ? v.fichaTipo.id : null,
                !(isNullOrUndefined(v.fichaVariante)) ? v.fichaVariante.id : null,
                !(isNullOrUndefined(v.idCarroceria)) ? new CarroceriaClass(v.idCarroceria) : new CarroceriaClass(),
                !(isNullOrUndefined(v.idLuces)) ? new LucesClass(v.idLuces) : new LucesClass(),
                !(isNullOrUndefined(v.idLunas)) ? new LunasClass(v.idLunas) : new LunasClass(),
                !(isNullOrUndefined(v.idRetrovisores)) ? new RetrovisoresClass(v.idRetrovisores) : new RetrovisoresClass(),
                !(isNullOrUndefined(v.idCalefaccion)) ? new CalefaccionClass(v.idCalefaccion) : new CalefaccionClass(),
                !(isNullOrUndefined(v.idInstPuertas)) ? new InstPuertasClass(v.idInstPuertas) : new InstPuertasClass(),
                !(isNullOrUndefined(v.idSeguridad)) ? new SeguridadClass(v.idSeguridad) : new SeguridadClass(),
                !(isNullOrUndefined(v.idTapiceria)) ? new TapiceriaClass(v.idTapiceria) : new TapiceriaClass(),
                !(isNullOrUndefined(v.idRodaje)) ? new RodajeClass(v.idRodaje) : new RodajeClass(),
                !(isNullOrUndefined(v.idMotor)) ? new MotorClass(v.idMotor) : new MotorClass(),
                !(isNullOrUndefined(v.idCambio)) ? new CambioClass(v.idCambio) : new CambioClass(),
                !(isNullOrUndefined(v.idEscape)) ? new EscapeClass(v.idEscape) : new EscapeClass(),
                !(isNullOrUndefined(v.fichaSeguimiento)) ? new SeguimientoClass(v.fichaSeguimiento) : new SeguimientoClass(),
                !(isNullOrUndefined(v.idEquipamento)) ? new EquipamientoClass(v.idEquipamento) : new EquipamientoClass(),
                v.tara,
                v.culata,
                v.parteBaja,
                v.caja,
                v.motor
              );
              obj.marcaDesc = !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.descripcion : '';
              obj.modeloDesc = !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.descripcion : '';
              vehic.push(obj);
            });
            this.PAGINATOR.next({
              pg: list.pg,
              pgSize: list.pgSize,
              count: list.count,
              pages: list.pages
            });
          }
          this.VEHICLES.next(vehic);
          this.LOADING.next(false);

        },
        (error: any) => { // on error
          console.error('Vehicles Service:', error);
          this.VEHICLES.next([]);
          this.LOADING.next(false);
        }
      );
    } else {
      this.VEHICLES.next(inCache);
      this.LOADING.next(false);
    }

  }

  public filter(filter: Array<any> = null) {
    const inCache: any = this.cacheData.get(this.configService.config.urls.vehicles + '-POST');
    this.LOADING.next(true);
    if (isNullOrUndefined(inCache)) {
      const rq = this.http.post(this.configService.config.urls.vehicles, {'filter': filter}, this.httpOptions).subscribe(
        (list: any) => {
          let vehic: Array<Vehicle> = [];
          if (!isNullOrUndefined(list)) {
            list.data.map(v => {
              const obj = new VehicleClass(
                v.id,
                v.fechaEntrada,
                v.fechaSalida,
                v.fichaMatricula,
                v.fichaTipoMotor,
                v.fichaDescripcion,
                v.fichaAnyo,
                v.fichaKilometros,
                v.fichaColor,
                v.fichaBastidor,
                v.fichaNumeroPuertas,
                v.fichaImagen1Url,
                v.fichaImagen1SmallThumbnailUrl,
                v.fichaImagen1AlbumId,
                v.fichaImagen1Id,
                v.fichaImagen2Url,
                v.fichaImagen2SmallThumbnailUrl,
                v.fichaImagen2AlbumId,
                v.fichaImagen2Id,
                v.ciPrecio,
                v.observaciones,
                v.bajaLogica,
                !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.id : null,
                !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.id : null,
                !(isNullOrUndefined(v.fichaTipo)) ? v.fichaTipo.id : null,
                !(isNullOrUndefined(v.fichaVariante)) ? v.fichaVariante.id : null,
                !(isNullOrUndefined(v.idCarroceria)) ? new CarroceriaClass(v.idCarroceria) : new CarroceriaClass(),
                !(isNullOrUndefined(v.idLuces)) ? new LucesClass(v.idLuces) : new LucesClass(),
                !(isNullOrUndefined(v.idLunas)) ? new LunasClass(v.idLunas) : new LunasClass(),
                !(isNullOrUndefined(v.idRetrovisores)) ? new RetrovisoresClass(v.idRetrovisores) : new RetrovisoresClass(),
                !(isNullOrUndefined(v.idCalefaccion)) ? new CalefaccionClass(v.idCalefaccion) : new CalefaccionClass(),
                !(isNullOrUndefined(v.idInstPuertas)) ? new InstPuertasClass(v.idInstPuertas) : new InstPuertasClass(),
                !(isNullOrUndefined(v.idSeguridad)) ? new SeguridadClass(v.idSeguridad) : new SeguridadClass(),
                !(isNullOrUndefined(v.idTapiceria)) ? new TapiceriaClass(v.idTapiceria) : new TapiceriaClass(),
                !(isNullOrUndefined(v.idRodaje)) ? new RodajeClass(v.idRodaje) : new RodajeClass(),
                !(isNullOrUndefined(v.idMotor)) ? new MotorClass(v.idMotor) : new MotorClass(),
                !(isNullOrUndefined(v.idCambio)) ? new CambioClass(v.idCambio) : new CambioClass(),
                !(isNullOrUndefined(v.idEscape)) ? new EscapeClass(v.idEscape) : new EscapeClass(),
                !(isNullOrUndefined(v.fichaSeguimiento)) ? new SeguimientoClass(v.fichaSeguimiento) : new SeguimientoClass(),
                !(isNullOrUndefined(v.idEquipamento)) ? new EquipamientoClass(v.idEquipamento) : new EquipamientoClass(),
                v.tara,
                v.culata,
                v.parteBaja,
                v.caja,
                v.motor
              );
              obj.marcaDesc = !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.descripcion : '';
              obj.modeloDesc = !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.descripcion : '';
              vehic.push(obj);
            });
            this.PAGINATOR.next({
              pg: list.pg,
              pgSize: list.pgSize,
              count: list.count,
              pages: list.pages
            });
          }
          this.VEHICLES.next(vehic);
          this.LOADING.next(false);

        },
        (error: any) => { // on error
          console.error('Vehicles Service:', error);
          this.VEHICLES.next([]);
          this.LOADING.next(false);
        }
      );
    } else {
      this.VEHICLES.next(inCache);
      this.LOADING.next(false);
    }

  }

  public search(clearCache: boolean = false, page: number = 1, pageSize: number = 10, search) {
    if (clearCache)
      this.clearCache();
    const size = Math.max(5, pageSize);
    const params = [
      `pg=${page}`,
      `pgSize=${size}`
    ];
    if (!isNullOrUndefined(search))
      params.push(`search=${search}`);
    const query = params.join('&');
    let url = encodeURI(`${this.configService.config.urls.vehicles}\?${query}`);
    url = url.replace(/\+/g, '%2b');
    const inCache: any = this.cacheData.get(this.configService.config.urls.vehicles);
    this.LOADING.next(true);
    if (isNullOrUndefined(inCache)) {
      const rq = this.http.get(url).subscribe(
        (list: any) => {
          let vehic: Array<Vehicle> = [];
          if (!isNullOrUndefined(list)) {
            if (list.data.length > 0)
              this.LASTINDEX.next(list.data[0].id + 1);
            else
              this.LASTINDEX.next(null);

            list.data.map(v => {
              const obj = new VehicleClass(
                v.id,
                v.fechaEntrada,
                v.fechaSalida,
                v.fichaMatricula,
                v.fichaTipoMotor,
                v.fichaDescripcion,
                v.fichaAnyo,
                v.fichaKilometros,
                v.fichaColor,
                v.fichaBastidor,
                v.fichaNumeroPuertas,
                v.fichaImagen1Url,
                v.fichaImagen1SmallThumbnailUrl,
                v.fichaImagen1AlbumId,
                v.fichaImagen1Id,
                v.fichaImagen2Url,
                v.fichaImagen2SmallThumbnailUrl,
                v.fichaImagen2AlbumId,
                v.fichaImagen2Id,
                v.ciPrecio,
                v.observaciones,
                v.bajaLogica,
                !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.id : null,
                !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.id : null,
                !(isNullOrUndefined(v.fichaTipo)) ? v.fichaTipo.id : null,
                !(isNullOrUndefined(v.fichaVariante)) ? v.fichaVariante.id : null,
                !(isNullOrUndefined(v.idCarroceria)) ? new CarroceriaClass(v.idCarroceria) : new CarroceriaClass(),
                !(isNullOrUndefined(v.idLuces)) ? new LucesClass(v.idLuces) : new LucesClass(),
                !(isNullOrUndefined(v.idLunas)) ? new LunasClass(v.idLunas) : new LunasClass(),
                !(isNullOrUndefined(v.idRetrovisores)) ? new RetrovisoresClass(v.idRetrovisores) : new RetrovisoresClass(),
                !(isNullOrUndefined(v.idCalefaccion)) ? new CalefaccionClass(v.idCalefaccion) : new CalefaccionClass(),
                !(isNullOrUndefined(v.idInstPuertas)) ? new InstPuertasClass(v.idInstPuertas) : new InstPuertasClass(),
                !(isNullOrUndefined(v.idSeguridad)) ? new SeguridadClass(v.idSeguridad) : new SeguridadClass(),
                !(isNullOrUndefined(v.idTapiceria)) ? new TapiceriaClass(v.idTapiceria) : new TapiceriaClass(),
                !(isNullOrUndefined(v.idRodaje)) ? new RodajeClass(v.idRodaje) : new RodajeClass(),
                !(isNullOrUndefined(v.idMotor)) ? new MotorClass(v.idMotor) : new MotorClass(),
                !(isNullOrUndefined(v.idCambio)) ? new CambioClass(v.idCambio) : new CambioClass(),
                !(isNullOrUndefined(v.idEscape)) ? new EscapeClass(v.idEscape) : new EscapeClass(),
                !(isNullOrUndefined(v.fichaSeguimiento)) ? new SeguimientoClass(v.fichaSeguimiento) : new SeguimientoClass(),
                !(isNullOrUndefined(v.idEquipamento)) ? new EquipamientoClass(v.idEquipamento) : new EquipamientoClass(),
                v.tara,
                v.culata,
                v.parteBaja,
                v.caja,
                v.motor
              );
              obj.marcaDesc = !(isNullOrUndefined(v.fichaMarca)) ? v.fichaMarca.descripcion : '';
              obj.modeloDesc = !(isNullOrUndefined(v.fichaModelo)) ? v.fichaModelo.descripcion : '';
              vehic.push(obj);
            });
            this.PAGINATOR.next({
              pg: list.pg,
              pgSize: list.pgSize,
              count: list.count,
              pages: list.pages
            });
          }
          this.VEHICLES.next(vehic);
          this.LOADING.next(false);

        },
        (error: any) => { // on error
          console.error('Vehicles Service:', error);
          this.VEHICLES.next([]);
          this.LOADING.next(false);
        }
      );
    } else {
      this.VEHICLES.next(inCache);
      this.LOADING.next(false);
    }

  }


  public create(data: any): Observable<any> {
    return this.http.post(this.configService.config.urls.vehicles + 'new', data, this.httpOptions);
  }

  public update(data: VehicleClass): Observable<any> {
    return this.http.put(this.configService.config.urls.vehicles + 'update/' + data.id, data, this.httpOptions);
  }

  public delete(id): Observable<any> {
    return this.http.delete(this.configService.config.urls.vehicles + 'delete/' + id);
  }

  public lastIdGenerated(): Promise<number> {
    const params = [
      `pg=${1}`,
      `pgSize=${1}`
    ];
    const query = params.join('&');
    let url = encodeURI(`${this.configService.config.urls.vehicles}\?${query}`);
    url = url.replace(/\+/g, '%2b');
    let id: number = null;
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        (list: any) => {
          if (!isNullOrUndefined(list)) {
            id = list.data[0].id + 1;
            this.LASTINDEX.next(id);
            resolve(id);
          }
        },
        (error: any) => { // on error
          reject(id);
        }
      );
      return id;
    });


  }

  /**
   * Este método es para limpiar la cache. O sea si ya pedí algo y no ha cambiado lo guardo
   * en la cache del navegador y antes de repetir la petición lo cojo de la cache
   */
  clearCache() {
    this.cacheData.clearReq(this.configService.config.urls.vehicles);
  }
}
