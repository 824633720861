import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class PartsTypeClass {
    form: FormGroup;

    constructor(public id: number = null,
                public descripcion: string = null,) {
        this.form = new FormGroup({
            id: new FormControl(this.id),
            descripcion: new FormControl({value: this.descripcion, disabled: false}, Validators.compose([
                Validators.required
            ]))
        });
    }
}
