import {Component, HostBinding, OnInit} from '@angular/core';
import {AppConfig} from '../../configs/app.config';
import {Observable} from 'rxjs';
import {defaultIfEmpty, map} from 'rxjs/operators';
import {RoutesConfig} from '../../configs/routes.config';
import {AppDialogAddComponent} from '../../shared/components/app-dialog-add/app-dialog-add.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {NavigationService} from '../../shared/services/navigation.service';
import {VehicleClass} from '../../shared/interfaces/vehicle-class';
import {Router} from '@angular/router';
import {PartClass} from '../../shared/interfaces/part-class';
import {AppDialogSearchComponent} from '../../shared/components/app-dialog-search/app-dialog-search.component';
import {EstanteriaClass} from '../../shared/interfaces/estanteria-class';
import {PartsTypeClass} from '../../shared/interfaces/parts-type-class';
import {AppUtils} from '../../shared/helpers/app.utils';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {
  routes = RoutesConfig.routes;
  @HostBinding('class.mobile') mobile;
  showFiller = false;
  entities: Array<any> = [];
  menus: Array<any> = [
    {icon: 'home', name: 'Inicio', link: ''},
    {icon: 'settings_applications', name: 'Configuración', link: 'config'},
    {icon: 'supervised_user_circle', name: 'Quiénes somos?', link: 'about'},
    {icon: 'person_pin', name: 'Dónde estamos?', link: 'ubication'},
    {icon: 'help_outline', name: 'Ayuda', link: 'help'}
  ];
  dialogAdd: MatDialogRef<AppDialogAddComponent>;
  dialogSearch: MatDialogRef<AppDialogSearchComponent>;

  get isMobile(): Observable<boolean> {
    return this.navigation.obsIsMobile;
  }
  public currentUserData:any;

  constructor(public dialog: MatDialog, public navigation: NavigationService, public router: Router) {
    this.entities = [
      {key: 'vehicle', icon: 'directions_car', name: 'Vehículo', link: this.routes.vehicles},
      {key: 'parts', icon: 'perm_data_setting', name: 'Pieza', link: this.routes.parts},
      {key: 'parts_types', icon: 'perm_data_setting', name: 'Tipo de Pieza', link: this.routes.partstypes},
      {key: 'shelves', icon: 'store', name: 'Estantería', link: this.routes.shelves}
    ];
    this.isMobile.subscribe(isMobile => {
      this.mobile = isMobile;
    });
    this.currentUserData = AppUtils.load('currentUserData');
  }

  ngOnInit() {

  }

  eventHandler(ev, entity, action) {
    ev.stopPropagation();
    ev.preventDefault();
    let e = null;
    if (entity === 'vehicle')
      e = new VehicleClass();
    if (entity === 'parts')
      e = new PartClass();
    if (entity === 'shelves')
      e = new EstanteriaClass();
    if (entity === 'parts_types')
      e = new PartsTypeClass();
    switch (action) {
      case 'add':
        this.showDialogAdd(e);
        break;
      case 'search':
        this.navigateToRoute(e);
        this.showDialogFilter(e);
        break;
    }

  }

  public showDialogAdd(entity: any) {
    const config = new MatDialogConfig();
    config.width = (this.mobile) ? '100%' : '60%';
    config.height = (this.mobile) ? '100%' : '85%';
    config.height = (entity instanceof EstanteriaClass) ? 'auto' : config.height;
    this.dialogAdd = this.dialog.open(AppDialogAddComponent, config);
    this.dialogAdd.componentInstance.entity = entity;
    this.dialogAdd.afterClosed().subscribe(result => {
      if (result)
        this.navigateToRoute(entity);
    });
  }

  navigateToRoute(key) {
    if (key instanceof VehicleClass)
      this.router.navigate([this.routes.vehicles]);
    if (key instanceof PartClass)
      this.router.navigate([this.routes.parts]);
    if (key instanceof PartsTypeClass)
      this.router.navigate([this.routes.partstypes]);
    if (key instanceof EstanteriaClass)
      this.router.navigate([this.routes.shelves]);
  }

  public showDialogFilter(entity: any) {
    const config = new MatDialogConfig();
    config.width = (this.mobile) ? '100%' : '50%';
    config.height = (this.mobile) ? '100%' : '80%';
    config.height = (entity instanceof EstanteriaClass) ? 'auto' : config.height;
    this.dialogSearch = this.dialog.open(AppDialogSearchComponent, config);
    this.dialogSearch.componentInstance.entity = entity;
    this.dialogSearch.afterClosed().subscribe(result => {
      // if(result )
      //     this.navigateToRoute(entity);

    });
  }
}
