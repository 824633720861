import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class LucesClass {
    form: FormGroup;
    public id: number = null;
    public catalizador:boolean = null;
    public primerTramo:boolean = null;
    public intermedio:boolean = null;
    public trasero:boolean = null;
    public faroIzdo:boolean = null;
    public faroDcho:boolean = null;
    public pilotoIzdo:boolean = null;
    public pilotoDcho:boolean = null;
    public antiIzdo:boolean = null;
    public antiDcho:boolean = null;
    public pilotoTrasIzdo:boolean = null;
    public pilotoTrasDcho:boolean = null;
    public mandoLuces:boolean = null;
    public mandoLimpia:boolean = null;
    public mandoInterm:boolean = null;
    public motorLimpiaDel:boolean = null;
    public motorLimpiaTras:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            faroIzdo: new FormControl({value: this.faroIzdo, disabled: false}),
            faroDcho: new FormControl({value: this.faroDcho, disabled: false}),
            pilotoIzdo: new FormControl({value: this.pilotoIzdo, disabled: false}),
            pilotoDcho: new FormControl({value: this.pilotoDcho, disabled: false}),
            antiIzdo: new FormControl({value: this.antiIzdo, disabled: false}),
            antiDcho: new FormControl({value: this.antiDcho, disabled: false}),
            pilotoTrasIzdo: new FormControl({value: this.pilotoTrasIzdo, disabled: false}),
            pilotoTrasDcho: new FormControl({value: this.pilotoTrasDcho, disabled: false}),
            mandoLuces: new FormControl({value: this.mandoLuces, disabled: false}),
            mandoLimpia: new FormControl({value: this.mandoLimpia, disabled: false}),
            mandoInterm: new FormControl({value: this.mandoInterm, disabled: false}),
            motorLimpiaDel: new FormControl({value: this.motorLimpiaDel, disabled: false}),
            motorLimpiaTras: new FormControl({value: this.motorLimpiaTras, disabled: false})
        });
    }
}
