import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class EscapeClass {
    form: FormGroup;
    public id: number = null;
    public catalizador: boolean = null;
    public primerTramo: boolean = null;
    public intermedio: boolean = null;
    public trasero: boolean = null;
    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            catalizador: new FormControl({value: this.catalizador, disabled: false}),
            primerTramo: new FormControl({value: this.primerTramo, disabled: false}),
            intermedio: new FormControl({value: this.intermedio, disabled: false}),
            trasero: new FormControl({value: this.trasero, disabled: false})
        });
    }
}
