import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class SeguridadClass {
    form: FormGroup;
    public id: number = null;
    public airbagVolante:boolean = null;
    public airbagCopiloto:boolean = null;
    public pretensorIzdo:boolean = null;
    public pretensorDcho:boolean = null;
    public centralitaAirbag:boolean = null;
    public cinturonDI:boolean = null;
    public cinturonDD:boolean = null;
    public cinturonTD:boolean = null;
    public cinturonCentral:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            airbagVolante: new FormControl({value: this.airbagVolante, disabled: false}),
            airbagCopiloto: new FormControl({value: this.airbagCopiloto, disabled: false}),
            pretensorIzdo: new FormControl({value: this.pretensorIzdo, disabled: false}),
            pretensorDcho: new FormControl({value: this.pretensorDcho, disabled: false}),
            centralitaAirbag: new FormControl({value: this.centralitaAirbag, disabled: false}),
            cinturonDI: new FormControl({value: this.cinturonDI, disabled: false}),
            cinturonDD: new FormControl({value: this.cinturonDD, disabled: false}),
            cinturonTD: new FormControl({value: this.cinturonTD, disabled: false}),
            cinturonCentral: new FormControl({value: this.cinturonCentral, disabled: false})
        });
    }
}
