import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {MatChipInputEvent, MatSnackBar} from '@angular/material';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import {NavigationService} from '../../services/navigation.service';
import {Observable, timer} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {NomService} from '../../services/nom.service';
import {isNullOrUndefined} from 'util';
import {NomModel} from '../../interfaces/nomModel';

@Component({
    selector: 'app-nom-autocomplete',
    templateUrl: './app-nom-autocomplete.component.html',
    styleUrls: ['./app-nom-autocomplete.component.scss']
})
export class AppNomAutocompleteComponent implements OnInit {
    @HostBinding('class.mobile') mobile;
    @HostBinding('class.medium-screen') mediumScreen;
    visible: boolean = true;
    @Input() txtLoad: string = '';
    removable: boolean = true;
    addOnBlur: boolean = true;

    // Enter, comma
    separatorKeysCodes = [ENTER, COMMA];
    _listString = '';
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Input() sync: boolean = false;
    @Input() img: boolean = true;
    _reload: boolean = false;
    @Input() imgSize: number = 25;
    @Input() abrev: boolean = true;
    @Input() isReady: boolean = true;
    @Input() type: string = null;
    @Input() txtPlaceHolder: string = 'Seleccionar';
    @Input() data: NomModel[] = [];
    @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
    @Output() add: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() customControl: FormControl;
    @Input() btnAdd: boolean = false;
    stateCtrl: FormControl;
    filteredNom: Observable<any[]>;
    _filter: any = null;
    @Input() set filter(value: any) {
        this._filter = value;
        if (!isNullOrUndefined(this._filter) && !isNullOrUndefined(this._filter.value) && this._filter.value !== ''){
            this.getListNom(value);
            timer(500).subscribe(val => this.customControl.setValue(null));
        }

    }

    @Input() set reload(value: any) {
        this._reload = value;
    }
    @Input() set autoSelect(value: boolean) {
      if(value){
        if (!isNullOrUndefined(this.customControl.value)) {
          const f = this.data.find(n => (n.id === this.customControl.value));
          if (!isNullOrUndefined(f))
            this.checkValue(f,false);
        }
      }

    }

    get isMobile(): Observable<boolean> {
        return this.navigation.obsIsMobile;
    }


    constructor(public snackBar: MatSnackBar,
                public navigation: NavigationService,
                public nomenclatorsServices: NomService) {
        this.mediumScreen = this.navigation.mediumScreenAndDown;
        this.stateCtrl = new FormControl('');
        this.isMobile.subscribe(isMobile => {
            this.mobile = isMobile;
        });

    }

    filterStates(search: string) {
        search = search.toLowerCase();
        return this.data.filter((p) => {
            return (p.descripcion.toLowerCase().search(search) != -1);
        });

    }

    checkValue(opt,setN = true) {
        if(setN)
            this.changeValue.emit(opt);
        this.stateCtrl.setValue(opt.descripcion);
        if (!isNullOrUndefined(this.customControl))
            this.customControl.setValue(opt.id);


    }

    checkControlStatus() {
        const find = this.data.find(x => this.customControl.value === x.id);
        if (!isNullOrUndefined(find))
            this.stateCtrl.setValue(find.descripcion);
        else
            this.stateCtrl.setValue('');
    }

    ngOnInit() {
        this.customControl = isNullOrUndefined(this.customControl) ? null : this.customControl;
        this.txtLoad = (this.txtLoad == '') ? this.txtPlaceHolder : this.txtLoad;
        if (!isNullOrUndefined(this.customControl)) {
            this.customControl.valueChanges.subscribe(value => {
                if (isNullOrUndefined(value) || value == '')
                    this.stateCtrl.setValue('');
            });
            this.checkControlStatus();

        }
        this.filteredNom = this.stateCtrl.valueChanges
            .pipe(
                startWith(''),
                map(nom => nom ? this.filterStates(nom) : this.data.slice())
            );
        this.getListNom();


    }

    getListNom(filter = null) {
        if (!isNullOrUndefined(this.type)) {
            this.sync = true;
            this.isReady = false;
            this.nomenclatorsServices.getAll(this.type, filter).then(data => {
                this.isReady = true;

                if (data) {
                    this.data = data;
                    this.load.emit(true);
                    if (!isNullOrUndefined(this.customControl.value)) {
                        const f = this.data.find(n => (n.id == this.customControl.value));
                        if (!isNullOrUndefined(f))
                            this.checkValue(f,false);
                    }
                }

                // console.log('DATAAAA IN COMPLETED ', this.data);

            });

        }
    }

    reloadData() {
        this.refresh.emit(true);
        if (this._reload) {
            this.nomenclatorsServices.reloadData(this.type);
            this.ngOnInit();
        }

    }


}
