import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {VehicleClass} from '../../interfaces/vehicle-class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VehicleService} from '../../services/vehicle.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import * as moment from 'moment';
import {PartClass} from '../../interfaces/part-class';
import {PartService} from '../../services/part.service';
import {EstanteriaClass} from '../../interfaces/estanteria-class';
import {EstanteriaService} from '../../services/estanteria.service';
import {isNullOrUndefined} from 'util';
import {ConfigService} from '../../services/config.services';

@Component({
    selector: 'app-dialog-view-image',
    templateUrl: './app-dialog-view-image.component.html',
    styleUrls: ['./app-dialog-view-image.component.scss']
})
export class AppDialogViewImageComponent implements OnInit {
    public url: any;

    constructor(public snackBar: MatSnackBar,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<any>,
                public configService: ConfigService) {

    }

    ngOnInit() {

    }

}
