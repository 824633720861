import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class InstPuertasClass {
    form: FormGroup;
    public id: number = null;
    public cerrajaDI:boolean = null;
    public cerrajaDD:boolean = null;
    public cerrajaTI:boolean = null;
    public cerrajaTD:boolean = null;
    public evalunasDI:boolean = null;
    public evalunasDD:boolean = null;
    public evalunasTI:boolean = null;
    public evalunasTD:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            cerrajaDI: new FormControl({value: this.cerrajaDI, disabled: false}),
            cerrajaDD: new FormControl({value: this.cerrajaDD, disabled: false}),
            cerrajaTI: new FormControl({value: this.cerrajaTI, disabled: false}),
            cerrajaTD: new FormControl({value: this.cerrajaTD, disabled: false}),
            evalunasDI: new FormControl({value: this.evalunasDI, disabled: false}),
            evalunasDD: new FormControl({value: this.evalunasDD, disabled: false}),
            evalunasTI: new FormControl({value: this.evalunasTI, disabled: false}),
            evalunasTD: new FormControl({value: this.evalunasTD, disabled: false})
        });
    }
}
