import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class LunasClass {
    form: FormGroup;
    public id: number = null;
    public lunaDel:boolean = null;
    public lunaTras:boolean = null;
    public lunaDI:boolean = null;
    public lunaDD:boolean = null;
    public lunaTI:boolean = null;
    public lunaTD:boolean = null;

    constructor(data: any = null) {
        if (!isNullOrUndefined(data))
            Object.keys(data).map(i => this[i] = data[i]);

        this.form = new FormGroup({
            id: new FormControl(this.id),
            lunaDel: new FormControl({value: this.lunaDel, disabled: false}),
            lunaTras: new FormControl({value: this.lunaTras, disabled: false}),
            lunaDI: new FormControl({value: this.lunaDI, disabled: false}),
            lunaDD: new FormControl({value: this.lunaDD, disabled: false}),
            lunaTI: new FormControl({value: this.lunaTI, disabled: false}),
            lunaTD: new FormControl({value: this.lunaTD, disabled: false})
        });
    }
}
