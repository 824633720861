import {Component, EventEmitter, Inject, OnInit, Output, Input} from '@angular/core';
// import {APP_CONFIG} from '../../../configs/app.config';
import {ProgressBarService} from '../../services/progress-bar.service';
import {NavigationEnd, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {AppUtils} from '../../helpers/app.utils';
import {timer} from 'rxjs';
import {AuthenticationService} from '../../services/auth/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    @Output() opened: EventEmitter<any> = new EventEmitter();
    @Output() selectionConfig: EventEmitter<any> = new EventEmitter();
    @Output() selectSearch: EventEmitter<boolean> = new EventEmitter();
    @Output() resetSearch: EventEmitter<boolean> = new EventEmitter();
    selectedLanguage: string;
    progressBarMode: string;
    currentUrl: string;
    @Input() title: string = 'INICIO';
    @Input() routerIcon: string = '';
    @Input() routerBack: string = '/';
    @Input() activeRouteBack = false;
    @Input() activeFilter = false;
    @Input() iconsTools = true;
    @Input() configs: any[] = [
        {key: 'help', label: 'Ayuda', icon: 'help'},
        {key: 'closesession', label: 'Cerrar sessión', icon: 'keyboard_backspace'}
    ];
    _opened = false;
    public currentUserData:any;

    // @Inject(APP_CONFIG) public appConfig: any,
    constructor(private progressBarService: ProgressBarService,
                private cookieService: CookieService,
                private router: Router,
                public authenticationService: AuthenticationService
                ) {
      this.currentUserData = AppUtils.load('currentUserData');
    }

    ngOnInit() {
        this.selectedLanguage = this.cookieService.get('language') || 'en';

        this.progressBarService.getUpdateProgressBar().subscribe((mode: string) => {
            timer(200).subscribe(val => this.progressBarMode = mode);
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });
    }

    changeLanguage(language: string): void {
        this.cookieService.put('language', language);
        this.selectedLanguage = language;
    }

    selectConfig(conf) {
        this.selectionConfig.emit(conf);
        switch (conf) {
            case 'closesession':
                this.closeSession();
                break;
        }
    }

    closeSession() {
        AppUtils.del('currentuser');
        AppUtils.del('currentUserData');
        this.authenticationService.logout();
        this.router.navigate(['/auth/login']);
    }
}
