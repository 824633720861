import {Injectable} from '@angular/core';
import {CacheService} from './cache.service';
import {ConfigService} from './config.services';
import {isNullOrUndefined} from 'util';
import {Vehicle} from '../interfaces/vehicle';
import {AppConfig} from '../../configs/app.config';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {EstanteriaClass} from '../interfaces/estanteria-class';
import {Estanteria} from '../interfaces/estanteria';

@Injectable({
    providedIn: 'root'
})
export class EstanteriaService {
    config = AppConfig;
    public httpOptions: any;
    private ESTANTERIAS: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    private PAGE: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    private PAGINATOR: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private LOADING: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get loading() {
        return this.LOADING.asObservable();
    }

    get estanterias() {
        return this.ESTANTERIAS.asObservable();
    }

    get page() {
        return this.PAGE.asObservable();
    }

    get paginator() {
        return this.PAGINATOR.asObservable();
    }

    constructor(private http: HttpClient,
                public cacheData: CacheService,
                public configService: ConfigService) {
        this.httpOptions = this.configService.getHttpOptions();
    }

    /**
     * Este método es para obtener todos los vehículos registrados
     * @param {number} pg  : Página actual
     * @param {number} pageSize  : Cantidad de elementos por página
     * @param {Array[any]} filter  : Arreglo de elementos para filtrar por cualquier atributo de la entidad
     * @param {boolean} clearCache  : Esto es para limpiar la cache.Siempre q se agregue o elimine o modifique algo
     * de un usuario este parametro debe pasarse verdadero para que vuelva a pedirlo al server
     */

    public index(clearCache: boolean = false, page: number = 1, pageSize: number = 10, filter: Array<any> = null) {
        this.LOADING.next(true);
        if (clearCache)
            this.clearCache();
        const size = Math.max(5, pageSize);
        const params = [
            `pg=${page}`,
            `pgSize=${size}`
        ];
        if (!isNullOrUndefined(filter))
            params.push(`filter=${JSON.stringify(filter)}`);
        const query = params.join('&');
        let url = encodeURI(`${this.configService.config.urls.estanterias}\?${query}`);
        url = url.replace(/\+/g, '%2b');
        const inCache: any = this.cacheData.get(this.configService.config.urls.estanterias);
        if (isNullOrUndefined(inCache)) {
            const rq = this.http.get(url).subscribe(
                (list: any) => {
                    let estant: Array<Estanteria> = [];
                    if (!isNullOrUndefined(list)) {
                        list.data.map(v => {
                            const obj = new EstanteriaClass(
                                v.id,
                                v.descripcion);
                            estant.push(obj);
                        });
                        this.PAGINATOR.next({
                            pg: list.pg,
                            pgSize: list.pgSize,
                            count: list.count,
                            pages: list.pages
                        });
                    }
                    this.ESTANTERIAS.next(estant);
                    this.LOADING.next(false);
                },
                (error: any) => { // on error
                    console.error('Estanterias Service:', error);
                    this.ESTANTERIAS.next([]);
                    this.LOADING.next(false);
                }
            )
        }
        else {
            this.ESTANTERIAS.next(inCache);
            this.LOADING.next(false);
        }

    }

    public filter(filter: Array<any> = null) {
        this.LOADING.next(true);
        const inCache: any = this.cacheData.get(this.configService.config.urls.estanterias+"-POST");
        if (isNullOrUndefined(inCache)) {
            const rq =this.http.post(this.configService.config.urls.estanterias, {'filter': filter}, this.httpOptions).subscribe(
                (list: any) => {
                    let estant: Array<Estanteria> = [];
                    if (!isNullOrUndefined(list)) {
                        list.data.map(v => {
                            const obj = new EstanteriaClass(
                                v.id,
                                v.descripcion);
                            estant.push(obj);
                        });
                        this.PAGINATOR.next({
                            pg: list.pg,
                            pgSize: list.pgSize,
                            count: list.count,
                            pages: list.pages
                        });
                    }
                    this.ESTANTERIAS.next(estant);
                    this.LOADING.next(false);
                },
                (error: any) => { // on error
                    console.error('Estanterias Service:', error);
                    this.ESTANTERIAS.next([]);
                    this.LOADING.next(false);
                }
            );
        }
        else {
            this.ESTANTERIAS.next(inCache);
            this.LOADING.next(false);
        }

    }

    public create(data: any): Observable<any> {
        return this.http.post(this.configService.config.urls.estanterias + 'new', data, this.httpOptions);
    }

    public update(data: EstanteriaClass): Observable<any> {
        return this.http.put(this.configService.config.urls.estanterias + 'update/' + data.id, data, this.httpOptions);
    }

    public delete(id): Observable<any> {
        return this.http.delete(this.configService.config.urls.estanterias + 'delete/' + id);
    }

    /**
     * Este método es para limpiar la cache. O sea si ya pedí algo y no ha cambiado lo guardo
     * en la cache del navegador y antes de repetir la petición lo cojo de la cache
     */
    clearCache() {
        this.cacheData.clearReq(this.configService.config.urls.estanterias);
    }
}
