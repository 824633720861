import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {isNullOrUndefined} from 'util';

export class PartClass {
  form: FormGroup;
  txtEstanteria: string = '';
  marcaDesc = '';
  modeloDesc = '';
  piezaDesc = '';

  constructor(public id: number = null,
              public idVehicleDm: number = null,
              public relocation: boolean = false,
              public descripcion: string = null,
              public fichaAnyo: string = null,
              public fechaInventariado: string = null,
              public fechaSalida: string = null,
              public ventaPrecioCliente: string = null,
              public ventaPrecioProfesional: string = null,
              public ventaPrecioReal: string = null,
              public imagenUrl: string = null,
              public imagenSmallThumbnailUrl: string = null,
              public imagenAlbumId: string = null,
              public imagenId: string = null,
              public bajaLogica: boolean = null,
              public zzPart: number = null,
              public idEstanteria: number = null,
              public fichaMarca: number = null,
              public fichaModelo: number = null,
              public idVehicleDmRelocated: number = null,
              public color: string = null,
              ) {
    const fechaS = (!moment(this.fechaSalida, 'DD-MM-YYYY').isValid()) ? null : moment(this.fechaSalida, 'DD-MM-YYYY').toDate();
    const fechaI = (!moment(this.fechaInventariado, 'DD-MM-YYYY').isValid()) ? moment().toDate() : moment(this.fechaInventariado, 'DD-MM-YYYY').toDate();
    this.form = new FormGroup({
      imageFile1: new FormControl(),
      id: new FormControl(this.id),
      idVehicleDm: new FormControl({value: this.idVehicleDm, disabled: false}, Validators.compose([])),
      relocation: new FormControl({value: this.relocation, disabled: false}, Validators.compose([])),
      fichaMarca: new FormControl({value: this.fichaMarca, disabled: false}, Validators.compose([
        Validators.required
      ])),
      fichaModelo: new FormControl({value: this.fichaModelo, disabled: false}, Validators.compose([
        Validators.required
      ])),
      idEstanteria: new FormControl({value: this.idEstanteria, disabled: false}, Validators.compose([
        Validators.required,
        Validators.maxLength(20)
      ])),
      idVehicleDmRelocated: new FormControl({value: this.idVehicleDmRelocated, disabled: false}, Validators.compose([
        Validators.maxLength(20)
      ])),
      zzPart: new FormControl({value: this.zzPart, disabled: false}, Validators.compose([
        Validators.required,
        Validators.maxLength(20)
      ])),
      descripcion: new FormControl({value: this.descripcion, disabled: false}),
      fichaAnyo: new FormControl({value: this.fichaAnyo, disabled: false}, Validators.compose([
        Validators.maxLength(4),
        Validators.minLength(4)
      ])),
      fechaInventariado: new FormControl({value: fechaI, disabled: false}),
      fechaSalida: new FormControl({value: fechaS, disabled: false}),
      ventaPrecioCliente: new FormControl({value: this.ventaPrecioCliente, disabled: false}),
      ventaPrecioProfesional: new FormControl({value: this.ventaPrecioProfesional, disabled: false}),
      ventaPrecioReal: new FormControl({value: this.ventaPrecioReal, disabled: false}),
      imagenUrl: new FormControl({value: this.imagenUrl, disabled: false}),
      imagenSmallThumbnailUrl: new FormControl({value: this.imagenSmallThumbnailUrl, disabled: false}),
      imagenAlbumId: new FormControl({value: this.imagenAlbumId, disabled: false}),
      imagenId: new FormControl({value: this.imagenId, disabled: false}),
      bajaLogica: new FormControl({value: this.bajaLogica, disabled: false}),
      color: new FormControl({value: this.color, disabled: false}),

    });
    // this.form.get('fichaMatricula').valueChanges.subscribe(value => {
    //     this.fichaMatricula = value;
    // });
  }
}
