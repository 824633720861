import {BrowserModule, ɵgetDOM} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {APP_BASE_HREF, DOCUMENT, isPlatformBrowser, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PrebootModule} from 'preboot';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {CookieModule} from 'ngx-cookie';
import {CoreModule} from './modules/core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {SharedModule} from './shared/shared.module';
import {AuthModule} from './auth/auth.module';
import {PagesModule} from './pages/pages.module';
import {Error404PageComponent} from './pages/error404-page/error404-page.component';
import {ConfigService, initConfig} from './shared/services/config.services';
import {JwtInterceptor} from './shared/helpers/jwt.interceptor';
import {ErrorInterceptor} from './shared/helpers/error.interceptor';

declare const require;


registerLocaleData(localeEs, 'es');

export function appInitializer(document: HTMLDocument, platformId: object) {
    return () => {
        if (isPlatformBrowser(platformId)) {
            const dom = ɵgetDOM();
            const styles: any[] = Array.prototype.slice.apply(dom.querySelectorAll(document, `style[ng-transition]`));
            styles.forEach(el => {
                // Remove ng-transition attribute to prevent Angular appInitializerFactory
                // to remove server styles before preboot complete
                el.removeAttribute('ng-transition');
            });
            document.addEventListener('PrebootComplete', () => {
                // After preboot complete, remove the server scripts
                setTimeout(() => styles.forEach(el => dom.remove(el)));
            });
        }
    };
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'scrappingmutilva'}),
        PrebootModule.withConfig({appRoot: 'app-root'}),
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CookieModule.forRoot(),
        LazyLoadImageModule.forRoot({}),
        CoreModule,
        SharedModule,
        AuthModule,
        PagesModule
    ],
    providers: [
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true
        },
        {provide: APP_BASE_HREF, useValue : '/' },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
